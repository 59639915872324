import { QueryParamConfig } from 'use-query-params'
import { BaseListSearchParams, Place } from './common'
import { Moment } from 'moment'

export enum ReservationStatus {
  ACTIVE,
  FINISHED,
  CANCELED
}

export type ReservationList = {
  totalCount: number
  reservations: Reservation[]
}

export type Reservation = {
  uuid: string
  status: ReservationStatus
  event: ListEvent
  place: Omit<Place, 'coordinates' | 'contact'>
  trainingRoom: TrainingRoom
  trainee?: ListTrainee
}

export type ReservationDetails = {
  uuid: string
  status: ReservationStatus
  passcode?: string
  event: Event
  place: Place
  trainingRoom: TrainingRoom
  trainee?: Trainee
}

export type ListEvent = {
  uuid: string
  formattedDate: string
  formattedStartTime: string
  startDate: Moment
}

export type Event = ListEvent & {
  duration: number
}

export type TrainingRoom = {
  uuid: string
  name: string
}

export type ListTrainee = {
  uuid: string
  firstName: string
  lastName: string | null
  fullName: string
  note: string | null
}

export type Trainee = ListTrainee & {
  contactEmail: string
  phone: string
}

export type ReservationListSearchParams = BaseListSearchParams & {
  from: QueryParamConfig<string | null | undefined, string | undefined>
  to: QueryParamConfig<string | null | undefined, string | undefined>
  locations: QueryParamConfig<string | null | undefined, string | undefined>
  rooms: QueryParamConfig<string | null | undefined, string | undefined>
  trainees: QueryParamConfig<string | null | undefined, string | undefined>
  order: QueryParamConfig<string | null | undefined, string>
}