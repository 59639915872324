import { useTheme } from '@mui/material'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { useField } from 'formik'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type Props = {
  name: string
  labels: {
    from: string
    to: string
  }
}

export default function FormikDateRangePicker(props: Props) {
  const theme = useTheme()
  const texts = useTranslation(['common', 'validations']).t
  // eslint-disable-next-line @typescript-eslint/no-unused-vars 
  const [field, meta, helpers] = useField(props.name)
  
  return (
    <DateRangePicker
      desktopModeMediaQuery={theme.breakpoints.up('md')}
      value={[
        field.value[0] ? moment(field.value[0], 'DD.MM.YYYY').toDate() : null,
        field.value[1] ? moment(field.value[1], 'DD.MM.YYYY').toDate() : null
      ]}
      onChange={(time) => {
        helpers.setValue([
          time[0] != null ? moment(time[0]).format('DD.MM.YYYY') : null,
          time[1] != null ? moment(time[1]).format('DD.MM.YYYY') : null
        ])
        helpers.setTouched(true, false)
      }}
      onClose={() => { helpers.setTouched(true, true) }}
      slotProps={{
        textField: ({ position }) => {
          const isError = (position === 'start' && !field.value[0] && field.value[1]) || (position === 'end' && !field.value[1] && field.value[0])
          return {
            label: position === 'start' ? props.labels.from : props.labels.to,
            placeholder: texts('common:date_placeholder'),
            error: isError,
            helperText: isError && texts('validations:field_required')
          }
        },
        dialog: {
          onClose: () => {
            // Empty function so dialog can only be close by dialog content
          }
        },
        fieldSeparator: {
          sx: {
            display: 'none'
          }
        },
        fieldRoot: {
          sx: {
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row'
            },
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column'
            },
            '.MuiFormControl-root': {
              [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginBottom: '1rem'
              },
            }
          }
        }
      }}
    />
  )
}