import { Close } from '@mui/icons-material'
import { IconButton, Dialog as MuiDialog, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { ReactElement, ReactNode } from 'react'

type Props = {
  open: boolean
  title: string | ReactElement
  onCloseButtonClick?: () => void
  children: ReactNode | ReactNode[]
  sx?: SxProps<Theme>
  closeButton?: boolean
}

export default function BaseDialog(props: Props) {
  const theme = useTheme()

  return (
    <MuiDialog open={props.open} onClose={props.onCloseButtonClick} sx={props.sx}>
      <Typography variant='h3'>
        {props.title}
      </Typography>
      {
        props.closeButton &&
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8
            }}
            onClick={props.onCloseButtonClick}
          >
            <Close 
              sx={{ 
                [theme.breakpoints.up('md')]: {
                  fontSize: '2rem'
                },
                [theme.breakpoints.down('md')]: {
                  fontSize: '2rem'
                },
                [theme.breakpoints.down('sm')]: {
                  fontSize: '1.5rem'
                }
              }} 
            />
          </IconButton>
      }
      {props.children}
    </MuiDialog>
  )
}