import { Grid, Typography, useTheme } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useMemo, useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { DecodedValueMap, StringParam, useQueryParams, withDefault } from 'use-query-params'
import Loader from '../../components/customMui/Loader'
import Calendar from '../../components/locations/calendar/Calendar'
import ControlSection, { DateRangeChangeType } from '../../components/locations/calendar/ControlSection'
import TopSection from '../../components/locations/calendar/TopSection'
import useGetEventList from '../../hooks/data/events/useGetEventList'
import useGetSelectedPlaces from '../../hooks/data/locations/useGetSelectedPlaces'
import useGetDeviceType from '../../hooks/device/useGetDeviceType'
import { queryNames } from '../../hooks/queries'
import { mapEventList } from '../../mapping/calendar'
import { mapSearchDataList } from '../../mapping/rooms'
import { CalendarSearchParams, TimeOfDay } from '../../types/calendar'
import { CalendarFilterFormData } from '../../types/form/filter'
import { getCalendarDateList, getDayHourList } from '../../utils/calendar'
import ConnectionError from '../Errors/ConnectionError'
import { useUpdateEffect } from 'usehooks-ts'
import { getChangeDataParams } from '../../components/locations/calendar/utils'

export function isSearchParamsValid(params: DecodedValueMap<CalendarSearchParams>): boolean {
  return (params.timesofday == null || params.timesofday?.split(',').filter(e => !(parseInt(e) in TimeOfDay)).length === 0)
    && moment(params.from, 'DD.MM.YYYY').isValid()
    && moment(params.to, 'DD.MM.YYYY').isValid()
    && (
      !params.selectedDate
      || (
          moment(params.selectedDate, 'DD.MM.YYYY').isValid()
          && moment(params.selectedDate, 'DD.MM.YYYY').isBetween(
            moment(params.from, 'DD.MM.YYYY'),
            moment(params.to, 'DD.MM.YYYY'),
            'd',
            '[]'
          )
        )
    )
}

export default function LocationEventsCalendar() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const texts = useTranslation('errors').t
  const theme = useTheme()

  const { uuid } = useParams<{ uuid?: string }>()
  const { type, isDesktop, isMobile } = useGetDeviceType()
  const dateRangeLength = isDesktop ? 7 : (isMobile ? 7 : 4)

  const [searchParams, setSearchParams] = useQueryParams<CalendarSearchParams>({ 
    from: withDefault(StringParam, moment().format('DD.MM.YYYY')),
    to: withDefault(StringParam, moment().add(dateRangeLength - 1, 'day').format('DD.MM.YYYY')),
    rooms: withDefault(StringParam, undefined),
    timesofday: withDefault(StringParam, undefined),
    selectedDate: withDefault(StringParam, isMobile ? moment().format('DD.MM.YYYY') : undefined)
  })

  useEffect(() => {
    if (!isSearchParamsValid(searchParams)) {
      setSearchParams({
        from: moment().format('DD.MM.YYYY'),
        to: moment().add(dateRangeLength - 1, 'day').format('DD.MM.YYYY'),
        rooms: undefined,
        timesofday: undefined,
        selectedDate: isMobile ? moment().format('DD.MM.YYYY') : undefined
      }, 'replaceIn')
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // This useEffect is so that in case someone changes width of browser on desktop
  // and it changes width device types it will reset date params
  // Without this there is a problem, because table range is only 4 days and calculating 
  // it into 7 day range or the other way might be problematic (and harder than this)
  useUpdateEffect(() => {
    setSearchParams({
      from: moment().format('DD.MM.YYYY'),
      to: moment().add(dateRangeLength - 1, 'day').format('DD.MM.YYYY'),
      selectedDate: isMobile ? moment().format('DD.MM.YYYY') : undefined
    }, 'replaceIn')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const selectedPlaces = useGetSelectedPlaces(
    [uuid ?? ''],
    (data) => {
      if (data.length === 0) {
        toast.error(texts('place_not_found'))
        navigate(-1)
      } else {
        queryClient.resetQueries([queryNames.getEventList, uuid])
      }
    },
    Boolean(uuid) && isSearchParamsValid(searchParams),
    true
  )

  const eventList = useGetEventList({
    enabled: Boolean(uuid) && !selectedPlaces.isFetching && isSearchParamsValid(searchParams),
    place: uuid!,
    from: moment(searchParams.from, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    to: moment(searchParams.to, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    timesofday: searchParams.timesofday,
    rooms: searchParams.rooms,
  })

  const dates = useMemo(() => {
    return getCalendarDateList(searchParams.from, searchParams.to)
  }, [searchParams.from, searchParams.to])

  const hours = useMemo(() => {
    return getDayHourList()
  }, [])

  if (selectedPlaces.isFetching) {
    return (
      <Grid marginTop='10rem' width='100%' overflow='hidden'>
        <Loader />
      </Grid>
    )  
  }

  if (selectedPlaces.isError || eventList.isError) {
    return (
      <ConnectionError
        onRefresh={() => {
          queryClient.resetQueries(selectedPlaces.isError ? [queryNames.getSelectedPlaces] : [queryNames.getEventList])
        }}
      />
    )
  }

  if (!isSearchParamsValid(searchParams) || selectedPlaces.data!.length === 0) {
    return <></>
  }

  const onDateRangeChange = (changeType: DateRangeChangeType) => {
    const changeData = getChangeDataParams(
      changeType,
      searchParams,
      type,
      dateRangeLength
    )
    setSearchParams(changeData.params, 'replaceIn')
    if (changeData.resetQuery) {
      queryClient.resetQueries([
        queryNames.getEventList,
        uuid,
        ...changeData.resetQuery
      ])
    }
  }

  const onFilterChange = (data: CalendarFilterFormData) => {
    setSearchParams({
      rooms: data.rooms.length > 0 ? data.rooms.map(e => e.value).join(',') : undefined,
      timesofday: data.timesOfDay.length > 0 ? data.timesOfDay.join(',') : undefined
    }, 'replaceIn')
  }
  
  const isFilterPresent = searchParams.rooms != null || searchParams.timesofday != null
  const initialFilterValues = isFilterPresent
    ? {
        rooms: searchParams.rooms != null 
          ? mapSearchDataList(selectedPlaces.data![0].trainingRooms).filter(r => searchParams.rooms!.split(',').includes(r.value))
          : [],
        timesOfDay: searchParams.timesofday?.split(',').map(e => parseInt(e)) ?? []
      }
    : undefined

  return (
    <Grid width='100%' paddingX='1rem'>
      <TopSection name={selectedPlaces.data![0].name} />
      <Grid 
        paddingY='2rem'
        sx={{
          [theme.breakpoints.down('sm')]: {
            marginTop: '.25rem'
          }
        }}
      >
        <Grid
          container
          flexDirection='column'
          alignItems='center'
        >
          {
            !isMobile &&
              <Typography
                variant='h4'
                marginBottom='1rem'
              >
                {`${moment(searchParams.from, 'DD.MM.YYYY').format('DD MMMM')} - ${moment(searchParams.to, 'DD.MM.YYYY').format('DD MMMM YYYY')}`}
              </Typography>
          }
          <ControlSection 
            initValues={initialFilterValues} 
            isFilterPresent={isFilterPresent}
            disabledButtons={[
              ...(
                isMobile
                ? (
                    moment(searchParams.selectedDate, 'DD.MM.YYYY').isSame(moment().startOf('d'))
                      ? [DateRangeChangeType.BACK]
                      : []
                  )
                : (
                    moment().isBetween(
                      moment(searchParams.from, 'DD.MM.YYYY'),
                      moment(searchParams.to, 'DD.MM.YYYY'),
                      'd',
                      '[]'
                    ) 
                      ? [DateRangeChangeType.BACK]
                      : []
                  )
              )
            ]}
            disableRoomFilter={false}
            onDateRangeChange={onDateRangeChange}
            onFilterChange={onFilterChange}
            rooms={mapSearchDataList(selectedPlaces.data![0].trainingRooms)}
          />
        </Grid>
        <Calendar
          events={mapEventList(eventList.data ?? [], hours, dates.map(d => d.date).filter(d => !isMobile || d === searchParams.selectedDate))}
          hours={hours}
          dates={dates.filter(d => !isMobile || d.date === searchParams.selectedDate)}
          loading={eventList.isFetching}
        />
      </Grid>
    </Grid>
  )
}