import { Namespace, TFunction } from 'i18next'
import * as Yup from 'yup'

export function reservationFilterFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    dateRange: Yup.array()
      .test('field_required', t('validations:field_required'), function (val, ctx) {
        const valLength = val?.length ?? 0
        const nullCount = val?.filter(d => d == null).length ?? 0
        const notNullCount = valLength - nullCount
        return nullCount !== 0 && notNullCount !== 0 
      })
  })
}
