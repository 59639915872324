import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { Place } from '../../../api/data/types'
import { mapListEntryDtoList } from '../../../mapping/locations'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
}

export default function useGetPlaceMapList(options: Place.MapListOptionsDto & Options) {
  const texts = useTranslation().t
  const key = [
    queryNames.getMapLocationList,
    options.southwest,
    options.northeast,
    options.lat,
    options.lon
  ]
  const query = useQuery(key, async () => {
    const { data } = await dataApi.getPlaceMapList(options)
    return mapListEntryDtoList(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
