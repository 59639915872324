import { Namespace, TFunction } from 'i18next'
import * as Yup from 'yup'
import { stripePublicKey, stripeSecretKey } from './regexp'

export function paymentConfigurationFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    publicKey: Yup.string()
      .required(t('validations:field_required'))
      .matches(stripePublicKey, t('validations:incorrect_field_format_short'))
      .max(256, t('validations:max_256_characters')),
    secretKey: Yup.string()
      .required(t('validations:field_required'))
      .matches(stripeSecretKey, t('validations:incorrect_field_format_short'))
      .max(256, t('validations:max_256_characters'))
  })
}

export function productFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    count: Yup.number()
      .required(t('validations:field_required'))
      .min(1, t('validations:positive_value')),
    validForCount: Yup.number()
      .required(t('validations:field_required'))
      .min(1, t('validations:positive_value')),
    validForUnit: Yup.string()
      .required(t('validations:field_required')),
    price: Yup.number()
      .required(t('validations:field_required'))
      .min(0.01, t('validations:positive_value')),
      taxRate: Yup.number()
      .required(t('validations:field_required'))
      .min(0.01, t('validations:positive_value'))
  })
}

