import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import LogoIconKolektywBlackImage from '../../assets/images/logoIconKolektywBlack.svg'
import Link from '../../components/customMui/Link'
import PasswordField from '../../components/customMui/PasswordField'
import TextField from '../../components/customMui/TextField'
import FacebookOauthButton from '../../components/social/buttons/FacebookOauthButton'
import GoogleOauthButton from '../../components/social/buttons/GoogleOauthButton'
import { LoginFormData } from '../../types/form/auth'
import { loginFormDataValidation } from '../../validations/auth'
import { Step } from './Registration'
import { useNavigate } from 'react-router-dom'
import { ProviderType } from '../../types/common'
import { useLogin } from '../../hooks/auth/useLogin'
import { mapLoginFormData } from '../../mapping/auth'
import { isAuthError } from '../../services/errors/utils'
import { AuthSubcodes } from '../../services/errors/consts'

export default function Login() {
  const theme = useTheme()
  const navigate = useNavigate()
  const texts = useTranslation('login').t

  const signinMutation = useLogin((error, variables) => {
    if (isAuthError(error, AuthSubcodes.EMAIL_NOT_VERIFIED)) {
      navigate('/registration', { 
        state: { 
          step: Step.ACTIVATE_ACCOUNT,
          email: variables.login,
          accountType: ProviderType.PASSWORD
        },
      })
    }
  })

  const handleSubmit = (data: LoginFormData) => {
    signinMutation.mutate(mapLoginFormData(data))
  }

  return (
    <Grid
      container
      flexDirection='column'
      alignItems='center'
      gap='1rem'
      textAlign='center'
      paddingX='2rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '4rem',
          width: '30rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '4rem',
          paddingBottom: '2rem',
          width: '30rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '2rem',
          width: '100vw'
        }
      }}
    >
      <Box
        component='img'
        src={LogoIconKolektywBlackImage}
        sx={{
          [theme.breakpoints.up('md')]: {
            height: '5.25rem'
          },
          [theme.breakpoints.down('md')]: {
            height: '4rem'
          },
          [theme.breakpoints.down('sm')]: {
            height: '4rem'
          }
        }}
      />
      <Typography variant='h1'>
        {texts('title')}
        <Box 
          component='span'
          color={theme.palette.secondary.main}
        >
          {texts('title_name')}
        </Box>
      </Typography>
      <Typography variant='subtitle1'>
        {texts('subtitle')}
      </Typography>
      <Grid
        container
        justifyContent='center'
        gap='2rem'
      >
        <FacebookOauthButton 
          onUserNotFound={(data) => { 
            navigate('/registration', { 
              state: { 
                step: Step.INTRODUCE_YOURSELF ,
                oauthData: {
                  token: data.token
                },
                accountType: ProviderType.FACEBOOK
              },
            })
          }}
        />
        <GoogleOauthButton
          onUserNotFound={(data) => { 
            navigate('/registration', { 
              state: { 
                step: Step.INTRODUCE_YOURSELF ,
                oauthData: {
                  token: data.token
                },
                accountType: ProviderType.GOOGLE
              },
            })
          }}
        />
      </Grid>
      <Typography 
        variant='body1'
        color='#BBBBBB'
      >
        {texts('or_label')}
      </Typography>
      <Grid width='100%'>
        <Formik<LoginFormData>
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={loginFormDataValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <TextField
                name='email'
                label={texts('email_field_label')}
                fullWidth
              />
              <PasswordField
                name='password'
                label={texts('password_field_label')}
              />
              <Grid
                textAlign='end'
                marginBottom='1.5rem'
              >
                <Typography variant='body1'>
                  <Link to='/password/reset'>
                    {texts('dont_remember_password')}
                  </Link>
                </Typography>
              </Grid>
              <LoadingButton
                type='submit'
                variant='contained'
                fullWidth
                loading={signinMutation.isLoading}
                disabled={!formikProps.dirty || !formikProps.isValid}
              >
                {texts('submit_button_label')}
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid>
        <Divider 
          sx={{
            marginTop: '.5em', 
            marginBottom: '1rem',
            width: '100%'
          }}
        />
        <Typography 
          variant='body1'
          marginTop='1rem'
          paddingX='1.25rem'
        >
          {texts('dont_have_account_label')}
          <Link to='/registration'>
            {texts('sign_up_label')}
          </Link>
        </Typography>
      </Grid> 
    </Grid>
  )
}