import { Account } from '../api/data/types'
import { ChangePwdFormData, ProfileFormData } from '../types/form/account'
import { Account as AccountType, ConnectionCode } from '../types/account'
import { Account as StorageAccount } from '../types/storage'
import { AccountDto } from '../api/types'

export function mapConnectionCodeDto(data: Account.ConnectionCodeDto): ConnectionCode {
  return {
    ...data
  }
}

export function mapProfileFormData(data: ProfileFormData, code?: string): Account.EditAccountDataDto {
  return {
    firstName: data.firstName.trim(),
    lastName: data.lastName.trim(),
    nick: data.nick.trim() ? data.nick.trim() : null,
    email: data.email.trim(),
    phone: data.phone.trim(),
    code: code
  }
}

export function mapAccountDto(data: AccountDto): AccountType {
  return {
    ...data
  }
}

export function mapAccountToAccountForm(data: StorageAccount): ProfileFormData {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    nick: data.nick ?? '',
    email: data.contact.email,
    phone: data.contact.phone,
  }
}

export function mapChangePwdFormData(data: ChangePwdFormData): Account.ChangePasswordDto {
  return {
    ...data
  }
}
