import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import AuthApi from '../../api/auth/AuthApi'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/errors'

const authApi = AuthApi.getInstance()

export function useResendVerificationEmail() {
  const texts = useTranslation().t

  const mutation = useMutation(async (email: string) => {
    const { data } = await authApi.resendVerificationEmail({ email: email })
    return data
  }, {
    onSuccess: () => {
      toast.success(texts('successes:verification_email_sent'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}