import { QueryParamConfig } from 'use-query-params'
import { RoomDetails, SearchData as RoomSearchData } from './rooms'
import { Address, BaseListSearchParams, Contact, Photo } from './common'
import { Upcoming } from './events'

export enum LocationListType {
  LIST = 'list',
  MAP = 'map'
}

export type LocationListListSearchParams = BaseListSearchParams & {
  'list-type': QueryParamConfig<string | null | undefined, string>
  search: QueryParamConfig<string | null | undefined, string | undefined>
}

export type SearchData = {
  uuid: string
  name: string
  tz: string
  active: boolean
  trainingRooms: RoomSearchData[]
}

export type LocationDetails = {
  uuid: string
  name: string  
  tz: string
  coordinates: number[]
  address: Address
  photo: Photo | null
  contact: Contact
  trainingRooms: RoomDetails[]
}

export type Room = {
  uuid: string
  name: string
  nextDates: string[]
  seats: number
  equipments: Equipment[]
  photo: Photo | null
}

export type Equipment = {
  uuid: string
  name: string
  photo: Photo
}

export type LocationList = {
  totalCount: number
  locations: Location[]
}

export type Location = {
  uuid: string
  name: string
  distance?: number
  coordinates: number[]
  tz: string
  address: Address
  photo: Photo | null
  upcomingEvent: Upcoming
}
