import { Trans, useTranslation } from 'react-i18next'
import Dialog from '../customMui/Dialog'
import { useRemoveTrainerProduct } from '../../hooks/data/product/useRemoveTrainerProduct'

type Props = {
  open: boolean
  uuid: string
  i18n: {
    entryCount: string
    validFor: string
    price: string
  }
  onClose: () => void
}

export default function RemoveProductDialog(props: Props) {
  const texts = useTranslation('removeProductDialog').t

  const mutation = useRemoveTrainerProduct()

  return (
    <Dialog
      open={props.open}
      title={<Trans i18nKey='removeProductDialog:title'/>}
      description={
        <Trans 
          i18nKey='removeProductDialog:description'
          values={{
            entryCount: props.i18n.entryCount,
            validFor: props.i18n.validFor,
            price: props.i18n.price
          }}
        />
      }
      warning={<Trans i18nKey='removeProductDialog:warning'/>}
      closeButtonLabel={texts('cancel')}
      confirmButtonLabel={texts('confirm')}
      loading={mutation.isLoading}
      onCloseButtonClick={props.onClose}
      onConfirmButtonClick={() => {
        mutation.mutate(props.uuid, {
          onSuccess: props.onClose
        })
      }}
    />
  )
}
