import { Grid } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { RadioButtonOption } from '../../../types/common'
import { ReservationListSearchParams } from '../../../types/reservations'
import DateRangeButton from './filterSection/DateRangeButton'
import FiltersButton from './filterSection/FiltersButton'
import SortButton from './filterSection/SortButton'
import { SearchData as LocationSearchData } from '../../../types/locations'
import { SearchData as TraineeSearchData } from '../../../types/trainees'
import { mapSearchDataList as mapLocationSearchDataList } from '../../../mapping/locations'
import { mapSearchData as mapRoomSearchData } from '../../../mapping/rooms'
import { mapSearchDataList as mapTraineeSearchDataList } from '../../../mapping/trainees'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'

type Props = {
  selectedLocations: LocationSearchData[] 
  selectedTrainees: TraineeSearchData[]
}

export default function ControlSection(props: Props) {
  const { isDesktop } = useGetDeviceType()
  const texts = useTranslation('reservationList').t

  const sortOptions: RadioButtonOption[] = texts('sort_options', {
    returnObjects: true 
  }) as RadioButtonOption[]

  const [searchParams, setSearchParams] = useQueryParams<ReservationListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    from: withDefault(StringParam, undefined),
    to: withDefault(StringParam, undefined),
    locations: withDefault(StringParam, undefined),
    rooms: withDefault(StringParam, undefined),
    trainees: withDefault(StringParam, undefined),
    order: withDefault(StringParam, sortOptions[0].value)
  })

  const isFilterPresent = searchParams.rooms != null || searchParams.locations != null || searchParams.trainees != null

  const initialFilterValues = {
    locations: mapLocationSearchDataList(props.selectedLocations),
    rooms: props.selectedLocations.length > 0 && searchParams.rooms != null
      ? props.selectedLocations.map(e => e.trainingRooms).flat().filter(e => searchParams.rooms?.split(',').includes(e.uuid)).map(mapRoomSearchData)
      : [],
    trainees: mapTraineeSearchDataList(props.selectedTrainees),
    dateRange: [
      searchParams.from,
      searchParams.to
    ] 
  } 

  return (
    <Grid
      container
      justifyContent='flex-end'
      gap='1rem'
      paddingTop='1.5rem'
      flexWrap='nowrap'
    >
      {
        isDesktop &&
          <DateRangeButton
            initValue={[
              searchParams.from ?? null,
              searchParams.to ?? null
            ]}
            onChange={(data) => {
              setSearchParams({
                from: data[0] ? moment(data[0]).format('DD.MM.YYYY') : undefined,
                to: data[1] ? moment(data[1]).format('DD.MM.YYYY') : undefined,
                page: 1
              }, 'replaceIn')
            }}
          />
      }
      <SortButton
        initValue={searchParams.order}
        onChange={(value) => {
          setSearchParams({
            order: value,
            page: 1
          }, 'replaceIn')
        }}
      />
      <FiltersButton
        initValues={initialFilterValues}
        isFilterPresent={isFilterPresent}
        onChange={(data) => {
          setSearchParams({
            locations: data.locations.length > 0
              ? data.locations.map(location => location.value).join(',')
              : undefined,
            rooms: data.rooms.length > 0
              ? data.rooms.map(room => room.value).join(',')
              : undefined,
              trainees: data.trainees.length > 0
              ? data.trainees.map(trainee => trainee.value).join(',')
              : undefined,
            page: 1,
            ...(
              !isDesktop 
              ? {
                  from: data.dateRange[0],
                  to: data.dateRange[1]
                } 
              : {}
            ),
          }, 'replaceIn')
        }}
      />
    </Grid>
  )
}