import { SvgIcon, SvgIconProps } from '@mui/material'

export default function DashboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
    >
      <g id="DashboardIcon" transform="translate(-12.000000, -12.000000)" fillRule="nonzero">
        <g id="dashboardIcon" transform="translate(12.000000, 12.000000)">
          <path d="M9.24993895,0 L1.74993895,0 C0.784973156,0 0,0.784973156 0,1.74993895 L0,6.24993895 C0,7.21508789 0.784973156,8.00006105 1.74993895,8.00006105 L9.24993895,8.00006105 C10.2150879,8.00006105 11.000061,7.21508789 11.000061,6.24993895 L11.000061,1.74993895 C11.000061,0.784973156 10.2150879,0 9.24993895,0 Z" id="Path"></path>
          <path d="M9.24993895,9.99993895 L1.74993895,9.99993895 C0.784973156,9.99993895 0,10.7849121 0,11.750061 L0,22.250061 C0,23.2150268 0.784973156,24 1.74993895,24 L9.24993895,24 C10.2150879,24 11.000061,23.2150268 11.000061,22.250061 L11.000061,11.750061 C11.000061,10.7849121 10.2150879,9.99993895 9.24993895,9.99993895 Z" id="Path"></path>
          <path d="M22.250061,15.999939 L14.750061,15.999939 C13.7849121,15.999939 12.999939,16.7849121 12.999939,17.750061 L12.999939,22.250061 C12.999939,23.2150268 13.7849121,24 14.750061,24 L22.250061,24 C23.2150268,24 24,23.2150268 24,22.250061 L24,17.750061 C24,16.7849121 23.2150268,15.999939 22.250061,15.999939 L22.250061,15.999939 Z" id="Path"></path>
          <path d="M22.250061,0 L14.750061,0 C13.7849121,0 12.999939,0.784973156 12.999939,1.74993895 L12.999939,12.249939 C12.999939,13.2150879 13.7849121,14.000061 14.750061,14.000061 L22.250061,14.000061 C23.2150268,14.000061 24,13.2150879 24,12.249939 L24,1.74993895 C24,0.784973156 23.2150268,0 22.250061,0 L22.250061,0 Z" id="Path"></path>
        </g>
      </g>
    </SvgIcon>
  )
}