import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { Event } from '../../../api/data/types'
import { mapEventDtoList } from '../../../mapping/events'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
}

export default function useGetEventList(options: Event.ListOptionsDto & Options) {
  const texts = useTranslation().t
  const key = [
    queryNames.getEventList,
    options.place,
    options.from,
    options.to,
    options.rooms,
    options.timesofday
  ]
  const query = useQuery(key, async () => {
    const { data } = await dataApi.getEventList(options)
    return mapEventDtoList(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
