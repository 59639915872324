import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { mapAccountDto } from '../../../mapping/account'
import { updateUserData } from '../../../redux/storageToolkit'
import store from '../../../redux/store'
import { Account } from '../../../types/account'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled?: boolean
  refetchInterval?: number
  onSuccess?: (data: Account) => void
}

export default function useGetAccountData(options: Options) {
  const texts = useTranslation().t

  const query = useQuery([queryNames.getAccountData], async () => {
    const { data } = await dataApi.getAccountData()
    return mapAccountDto(data.data!)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    refetchInterval: options.refetchInterval,
    onSuccess: (data) => {
      store.dispatch(updateUserData(data))
      options.onSuccess?.(data)
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
