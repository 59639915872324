import { Stripe } from '../api/data/types'
import { PaymentConfigurationFormData } from '../types/form/payments'
import { StripeConfig } from '../types/payments'

export function mapConfigDto(data: Stripe.ConfigDto): StripeConfig {
  return {
    ...data
  }
}

export function mapPaymentConfigurationFormData(data: PaymentConfigurationFormData): Stripe.ConfigDto {
  return {
    ...data
  }
}