import { Card, CardContent, Grid, Skeleton } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import PlaceholderImage from '../../../assets/images/locationPlaceholder.webp'

type Props = {
  src?: string
}

export default function Image(props: Props) {
  return (
    <Card>
      <CardContent sx={{ padding: 0, ':last-child': { padding: 0 } }}>
        <Grid 
          sx={{ 
            position: 'relative',
            fontSize: 0,
            width: '100%',
            height: 'auto',
            aspectRatio: '1.78',
          }}
        >
          <Grid sx={{ paddingBottom: '56.16%' }} />
          <LazyLoadImage
            src={props.src ?? PlaceholderImage}
            placeholder={          
              <Skeleton
                variant='rectangular'
                width='100%'
                height='100%'
                sx={{
                  position: 'absolute',
                  inset: 0,
                  borderRadius: '.5rem'
                }}
              />
            }
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '.5rem',
              position: 'absolute',
              inset: 0
            }}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}