import { KeyboardBackspace } from '@mui/icons-material'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function TopSection() {
  const theme = useTheme()
  const texts = useTranslation(['common', 'inviteTrainee']).t
  const navigate = useNavigate()

  return (
    <Grid
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '2rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: '1.5rem'
        }
      }}
    >
      <Button 
        variant='text'
        onClick={() => { navigate(-1) }}
      >
        <Grid
          container
          alignItems='center'
          gap='.5rem'
        >
          <KeyboardBackspace sx={{ marginTop: '-.125rem' }} />
          {texts('common:back')}
        </Grid>
      </Button>
      <Grid marginTop='1rem'>
        <Typography variant='h2'>
          {texts('inviteTrainee:title')}
        </Typography>
      </Grid>
    </Grid>
  )
}