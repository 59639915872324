import { useState } from 'react'
import ProvideEmail from '../../components/pages/auth/pwdReset/ProvideEmail'
import FinishPasswordReset from '../../components/pages/auth/pwdReset/FinishPasswordReset'
import VerifyCode from '../../components/pages/auth/pwdReset/VerifyCode'

enum Step {
  PROVIDE_EMAIL,
  VERIFY_CODE,
  FINISH_PASSWORD_RESET
}

export default function ResetPassword() {
  const [step, setStep] = useState<Step>(Step.PROVIDE_EMAIL)
  const [email, setEmail] = useState<string | null>(null)
  const [token, setToken] = useState<string | null>(null)

  // TODO add GA Page View hook

  switch (step) {
    case Step.PROVIDE_EMAIL:
      return <ProvideEmail 
        onSuccess={(email) => {
          setEmail(email)
          setStep(Step.VERIFY_CODE)
        }}
      />
    case Step.VERIFY_CODE:
      return <VerifyCode
        email={email!}
        onSuccess={(token) => {
          setStep(Step.FINISH_PASSWORD_RESET)
          setToken(token)
        }}
      />
    case Step.FINISH_PASSWORD_RESET:
      return <FinishPasswordReset 
        token={token!}
      />
  }
}