import { Clear, DateRange as DateRangeIcon } from '@mui/icons-material'
import { Button, Grid, IconButton, Popover, Typography } from '@mui/material'
import { DateRange, DateRangeCalendar, MultiInputDateRangeField } from '@mui/x-date-pickers-pro'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PillButton from '../../../customMui/PillButton'

type Props = {
  initValue: DateRange<string>
  onChange: (data: DateRange<Date>) => void
}

export default function DateRangeButton(props: Props) {
  const texts = useTranslation(['common', 'reservationList']).t
  
  const [value, setValue] = useState<DateRange<Date>>([null, null])
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  // useEffect for setting correct current state when opening popover
  useEffect(() => {
    setValue([
      props.initValue[0] ? moment(props.initValue[0], 'DD.MM.YYYY').toDate() : null,
      props.initValue[1] ? moment(props.initValue[1], 'DD.MM.YYYY').toDate() : null
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(anchorEl)])

  return (
    <>
      <Grid
        sx={{
          position: 'relative'
        }}
      >
        <PillButton
          variant='outlined'
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget)
          }}
        >
          <DateRangeIcon 
            sx={{ 
              marginRight: '.25rem',
              marginTop: '-.125rem',
              fontSize: '.875rem' 
            }} 
          />
          <Typography 
            variant='button2'
            sx={{
              marginTop: '.125rem',
              marginRight: props.initValue[0] && props.initValue[1] ? '1rem' : 0
            }}
          >
            {
              props.initValue[0] && props.initValue[1]
              ? texts(
                  'reservationList:date_range_button_label', 
                  { 
                    from: props.initValue[0] ?? '',
                    to: props.initValue[1] ?? ''
                  }
                )
              : texts('reservationList:date_range_button_no_dates_label')
            }
          </Typography>
        </PillButton>
        {
          props.initValue[0] && props.initValue[1] &&
            <IconButton
              sx={{
                padding: '.125rem',
                position: 'absolute',
                right: '.5rem',
                top: '50%',
                transform: 'translateY(-50%)'
              }}
              onClick={() => {
                setValue([null, null])
                props.onChange([null, null])
              }}
            >

            
              <Clear 
                sx={{
                  fontSize: '1.25rem',
                }}
              />
            </IconButton>
        }
      </Grid>
      <Popover 
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid sx={{ padding: '1rem'}}>
          <Typography variant='h4'>
            {texts('reservationList:date_range_title')}
          </Typography>
          <Grid marginTop='1.5rem'>
            <MultiInputDateRangeField 
              defaultValue={[
                props.initValue[0] ? moment(props.initValue[0], 'DD.MM.YYYY').toDate() : null,
                props.initValue[1] ? moment(props.initValue[1], 'DD.MM.YYYY').toDate() : null
              ]}
              slotProps={{
                textField: ({ position }) => ({
                  label: position === 'start' ? texts('reservationList:date_range_from') : texts('reservationList:date_range_to'),
                  placeholder: texts('common:date_placeholder')
                })
              }}
              value={value}
              disabled
            />
            <DateRangeCalendar
              calendars={2}
              defaultValue={[
                props.initValue[0] ? moment(props.initValue[0], 'DD.MM.YYYY').toDate() : null,
                props.initValue[1] ? moment(props.initValue[1], 'DD.MM.YYYY').toDate() : null
              ]}
              value={value}
              onChange={(newValue) => {
                setValue(newValue)
              }}
            />
          </Grid>
          <Grid
            container
            justifyContent='flex-end'
          >
            <Button
              type='submit'
              variant='contained'
              sx={{ width: '10rem' }}
              disabled={value.filter(d => d).length !== 2}
              onClick={() => {
                handleClose()
                props.onChange(value)
              }}
            >
              {texts('reservationList:date_range_submit_label')}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

