import { Grid, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import EmptyCalendarViewImage from '../../../assets/images/emptyCalendarView.webp'
import Loader from '../../customMui/Loader'
import { CalendarDate, CalendarEntry } from '../../../types/calendar'
import EmptyListView from '../../common/EmptyListView'
import CalendarEvent from './CalendarEvent'

type Props = {
  events: CalendarEntry[]
  dates: CalendarDate[]
  hours: string[]
  loading: boolean
}

export default function Calendar(props: Props) {
  const texts = useTranslation('locationCalendar').t

  const isNoData = useMemo(() => 
    props.events.filter(hour => 
      hour.data.filter(day => day.data.length > 0).length > 0
    ).length === 0, 
  [props.events])

  return (
    <Table>
      <colgroup>
        <col style={{ width: '5%' }}/>
        {
          props.dates.map(_ => (
            <col style={{ width: `${95/props.dates.length}%` }}/>
          ))
        }
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell/>
          {
            props.dates.map(date => (
              <TableCell>
                <Grid
                  container
                  flexDirection='column'
                  alignItems='center'
                >
                  <Typography
                    variant='body1'
                    fontWeight={500}
                  >
                    {date.label}
                  </Typography>
                  <Typography
                    variant='body2'
                    fontWeight={300}
                  >
                    {texts('week_day_labels', { day: date.dayOfWeek }) }
                  </Typography>
                </Grid>
              </TableCell>
            ))
          }
        </TableRow>
      </TableHead>
      {
        props.events.map((row, idx) => (
          <TableRow>
            <TableCell 
              sx={{ 
                padding: '.5rem', 
                verticalAlign: 'top',
              }}
            >
              <Typography
                variant='body2'
                fontWeight={500}
              >
                {row.hour}
              </Typography>
            </TableCell>
            {
              props.loading && idx === 0 
              && <TableCell
                  rowSpan={props.hours.length}
                  colSpan={props.dates.length}
                >
                  <Loader />
                </TableCell>
            }
            {
              !props.loading && isNoData && idx === 0 
              && <TableCell
                  rowSpan={props.hours.length}
                  colSpan={props.dates.length}
                >
                  <EmptyListView
                    image={EmptyCalendarViewImage}
                    title={texts('no_data_title')}
                    description={texts('no_data_description')}
                  />
                </TableCell>
            }
            {
              !props.loading && !isNoData && row.data.map(day => (
                <TableCell sx={{ padding: '.5rem', verticalAlign: 'top'}}>
                  <Grid
                    container
                    height='100%'
                    flexDirection='column'
                    alignItems='center'
                    gap='.5rem'
                  >
                    {
                      day.data.map(e => (
                        <CalendarEvent event={e} />
                      ))
                    }
                  </Grid>
                </TableCell>
              ))
            }
          </TableRow>
        ))
      }
    </Table>
  )
}

