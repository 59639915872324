import { Place } from '../api/data/types'
import { LocationSearchOption } from '../types/filters'
import { Location, LocationDetails, LocationList, SearchData } from '../types/locations'
import { mapSearchData as mapRoomSearchData } from './rooms'

export function mapSearchDtoList(data: Place.SearchDto[]): SearchData[] {
  return data.map(mapSearchDto)
}

export function mapSearchDto(data: Place.SearchDto): SearchData {
  return {
    ...data
  }
}

export function mapSearchDataList(data: SearchData[]): LocationSearchOption[] {
  return data.map(mapSearchData)
}

export function mapSearchData(data: SearchData): LocationSearchOption {
  return {
    value: data.uuid,
    label: data.name,
    tz: data.tz,
    active: data.active,
    trainingRooms: data.trainingRooms.map(mapRoomSearchData)
  }
}

export function mapListDto(data: Place.ListDto): LocationList {
  return {
    totalCount: data.totalCount,
    locations: data.places.map(mapListEntryDto)
  }
}

export function mapListEntryDto(data: Place.ListEntryDto): Location {
  return {
    ...data
  }
}

export function mapDetailsDto(data: Place.DetailsDto): LocationDetails {
    return {
      ...data
    }
  }

export function mapListEntryDtoList(data: Place.ListEntryDto[]): Location[] {
  return data.map(mapListEntryDto)
}


