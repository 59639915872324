import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { ReservationDetails, ReservationList, ReservationStatus } from '../../../types/reservations'
import { queryNames } from '../../queries'
import store from '../../../redux/store'
import { updatePackageData } from '../../../redux/storageToolkit'
import { SessionData } from '../../../types/session'

const dataApi = DataApi.getInstance()

export function useCancelReservation() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const mutation = useMutation(async (uuid: string) => {
    const { data } = await dataApi.cancelReservation(uuid)
    return data
  }, {
    onSuccess: (data, uuid) => {
      store.dispatch(updatePackageData(data.data!))
      let queriesData = queryClient.getQueriesData<ReservationList>([queryNames.getReservationList])
      for (const queryData of queriesData) {
        queryClient.setQueryData(queryData[0], (oldData?: ReservationList) => {
          if (!oldData) {
            return oldData
          }
          const newData = [...oldData.reservations]
          const index = newData.findIndex(p => p.uuid === uuid)
          newData[index] = {
            ...newData[index],
            status: ReservationStatus.CANCELED
          }
          return {
            totalCount: oldData.totalCount,
            reservations: newData
          }
        })
      }
      
      queryClient.setQueryData([queryNames.getReservationDetails, uuid], (oldData?: ReservationDetails) => {
        if (!oldData) {
          return oldData
        }
        return {
          ...oldData,
          status: ReservationStatus.CANCELED
        }
      })

      queryClient.setQueryData([queryNames.getSessionData], (oldData?: SessionData) => {
        if (!oldData) {
          return oldData
        }
        const newReservations = [...oldData.reservations]
        newReservations.splice(oldData.reservations.findIndex(r => r.uuid === uuid), 1)
        return {
          ...oldData,
          reservations: newReservations
        }
      })

      toast.success(texts('successes:reservation_cancelled'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}