import { Sort } from '@mui/icons-material'
import { Button, FormControlLabel, Grid, Popover, Radio, RadioGroup, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RadioButtonOption } from '../../../../types/common'
import PillButton from '../../../customMui/PillButton'

type Props = {
  initValue: string
  onChange: (data: string) => void
}

export default function SortButton(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('reservationList').t

  const sortOptions: RadioButtonOption[] = texts('sort_options', {
    returnObjects: true 
  }) as RadioButtonOption[]
  
  const [value, setValue] = useState<string>(sortOptions[0].value)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  // useEffect for setting correct current state when opening popover
  useEffect(() => {
    setValue(props.initValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(anchorEl)])

  return (
    <>
      <PillButton
        variant='outlined'
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget)
        }}
      >
        <Sort 
          sx={{ 
            marginRight: '.25rem',
            marginTop: '-.125rem',
            fontSize: '.875rem' 
          }} 
        />
        <Typography 
          variant='button2'
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {texts(
            'sort_button_label', { 
              order: sortOptions.find(e => e.value === props.initValue)?.label ?? sortOptions[0].label
            }
          )}
        </Typography>
      </PillButton>
      <Popover 
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid
          sx={{
            padding: '1rem',
            [theme.breakpoints.up('md')]: {
              width: '17rem'
            },
            [theme.breakpoints.down('md')]: {
              width: '17rem'
            },
            [theme.breakpoints.down('sm')]: {
              width: '15rem'
            }
          }}
        >
          <Typography variant='h4'>
            {texts('sort_by_title')}
          </Typography>
          <Grid padding='1rem'>
            <RadioGroup
              value={value}
              onChange={(_, value: string) => {
                setValue(value)
              }}
            >
              {
                sortOptions.map(option => (
                  <FormControlLabel 
                    value={option.value}
                    control={<Radio color='secondary'/>} 
                    label={option.label}
                  />
                ))
              }
            </RadioGroup>
          </Grid>
          <Grid
            container
            justifyContent='flex-end'
          >
            <Button
              type='submit'
              variant='contained'
              sx={{ width: '10rem' }}
              onClick={() => {
                handleClose()
                props.onChange(value)
              }}
            >
              {texts('sort_button_submit_label')}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

