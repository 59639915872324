import { GoogleOAuthProvider, useGoogleLogin as useGoogleOauthLogin } from '@react-oauth/google'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import GoogleIconImage from '../../../assets/images/googleIcon.png'
import { useGoogleLogin } from '../../../hooks/auth/useGoogleLogin'
import { AuthSubcodes } from '../../../services/errors/consts'
import { isAuthError } from '../../../services/errors/utils'
import { OauthData } from '../../../types/common'
import OauthButton from './OauthButton'

type Props = {
  onUserNotFound: (data: OauthData) => void
}

function Button(props: Props) {
  const texts = useTranslation('errors').t
  
  const loginMutation = useGoogleLogin((error, variables) => {
    if (isAuthError(error, AuthSubcodes.USER_NOT_FOUND)) {
      props.onUserNotFound({ token: variables.token })
    }
  })

  const login = useGoogleOauthLogin({
    onSuccess: async (response) => {
      loginMutation.mutate({
        token: response.access_token
      })
    },
    onError: () => {
      toast.error(texts('social_account_not_available'))
    },
    onNonOAuthError: (err) => {
      toast.error(texts(
        err.type === 'popup_closed'
          ? 'social_account_login_cancelled'
          : 'social_account_not_available'
      ))
    }
  })

  return (
    <OauthButton
      icon={GoogleIconImage}
      bgColor='#fff'
      onClick={login}
    />
  )
}

export default function GoogleOauthButton(props: Props) {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <Button {...props} />
    </GoogleOAuthProvider>
  )
}