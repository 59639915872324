import { CalendarEntry } from '../types/calendar'
import moment from 'moment'
import { Event } from '../types/events'

export function mapEventList(data: Event[], hours: string[], dates: string[]): CalendarEntry[] {
  const mappedData: CalendarEntry[] = hours.map(h => {
    return {
      hour: h,
      data: dates.map(d => {
        return {
          date: d,
          data: []
        }
      })
    }
  })

  // TODO implement mapping data
  data.forEach(value => {
    const date = moment(value.start, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY')
    const time = moment(value.start, 'YYYY-MM-DD HH:mm').startOf('hour').format('HH:mm')
    const timeIdx = mappedData.findIndex(e => e.hour === time)
    if (timeIdx < 0) {
      return
    }
    const dateIdx = mappedData[timeIdx].data.findIndex(e => e.date === date)
    if (dateIdx < 0) {
      return
    }
    mappedData[timeIdx].data[dateIdx].data.push(value)
  })

  return mappedData
}