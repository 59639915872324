import { Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PaymentSettingsCardView from '../../components/payments/configuration/PaymentSettingsCardView'
import MyProductsSection from '../../components/payments/configuration/MyProductsSection'
import useGetTrainerProductList from '../../hooks/data/product/useGetTrainerProductList'
import { useQueryClient } from '@tanstack/react-query'
import { queryNames } from '../../hooks/queries'
import ConnectionError from '../Errors/ConnectionError'
import Loader from '../../components/customMui/Loader'
import useGetStripeConfig from '../../hooks/data/payments/useGetStripeConfig'

export default function PaymentsConfiguration() {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const texts = useTranslation('paymentsConfiguration').t

  const config = useGetStripeConfig()

  const productList = useGetTrainerProductList({
    limit: parseInt(process.env.REACT_APP_PRODUCT_LIST_LIMIT!),
    offset: 0,
    enabled: config.data != null
  })

  if (config.isLoading || productList.isFetching) {
    return (
      <Grid marginTop='10rem' width='100%' overflow='hidden'>
        <Loader />
      </Grid>
    )  
  }

  if (config.isError || productList.isError) {
    return (
      <ConnectionError
        onRefresh={() => {
          queryClient.resetQueries(productList.isError ? [queryNames.getTrainerProductList] : [queryNames.getStripeConfig])
        }}
      />
    )
  }

  return (
    <Grid
      width='100%'
      paddingX='1rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingBlock: '2rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingBlock: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '1.5rem'
        }
      }}
    >
      <Typography variant='h2'>
        {texts('title')}
      </Typography>
      <Grid
        container
        flexDirection='column'
        alignItems='center'
      >
        <Grid
          container
          marginTop='1.5rem'
          flexDirection='column'
          sx={{
            [theme.breakpoints.up('md')]: {
              gap: '1.5rem',
              width: '80%'
            },
            [theme.breakpoints.down('md')]: {
              gap: '1rem',
              width: '100%'
            },
            [theme.breakpoints.down('sm')]: {
              gap: '.75rem',
              width: '100%'
            }
          }}
        >
          <PaymentSettingsCardView 
            config={config.data}
          />
          <MyProductsSection 
            enabled={config.data != null}
            products={productList.data!}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}