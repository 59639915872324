import { Namespace, TFunction } from 'i18next'
import * as Yup from 'yup'
import { emailRegex } from './regexp'

export function inviteTraineeFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    email: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(emailRegex, t('validations:email'))
  })
}

export function editTraineeNoteFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    note: Yup.string()
      .max(256, t('validations:max_256_characters'))
  })
}

export function addPackageFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    package: Yup.string()
      .required(t('validations:field_required'))
  })
}