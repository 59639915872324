import { Fab, FabProps, Slide, useScrollTrigger } from '@mui/material'

export default function HidingFab(props: FabProps) {
  const trigger = useScrollTrigger()
  return (
    <Slide 
      in={!trigger} 
      direction='up'
    >
      <Fab {...props}>
        {props.children}
      </Fab>
    </Slide>
  )
}