import styled from '@emotion/styled'
import { Box, Card, Grid, Popover, useTheme } from '@mui/material'
import { GoogleMap, InfoBoxF, Marker, useJsApiLoader } from '@react-google-maps/api'
import { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import LocationMapPinImage from '../../../assets/images/locationMapPin.svg'
import useGetDeviceType, { Device } from '../../../hooks/device/useGetDeviceType'
import useGetCurrentLocation from '../../../hooks/location/useGetCurrentLocation'
import { getUserCoordinates } from '../../../services/storage/storageService'
import { Location } from '../../../types/locations'
import Loader from '../../customMui/Loader'
import MapLocationCardView from './MapLocationCardView'

type Props = {
  onBoundsChange: (bounds: google.maps.LatLngBounds | null) => void
  locations: Location[]
  isFetching: boolean
}

const MapContainer = styled(Box)({
  img: {
    position: 'absolute'
  },
  '.infoBox': {
    img: {
      display: 'none'
    },
    '.MuiGrid-root': {
      img: {
        display: 'block'
      }
    }
  }
})

function getMapStyle(deviceType: Device) {
  switch (deviceType) {
    case Device.DESKTOP:
      return {
        height: 'calc(100vh - 14.25rem)',
        maxHeight: '40rem'
      }
    case Device.TABLET:
      return {
        height: 'calc(100vh - 14.75rem)',
        maxHeight: '100%'
      }
    case Device.MOBILE:
      return {
        height: 'calc(100vh - 14.25rem)'
      }
  }
}

export default function MapView(props: Props) {
  const theme = useTheme()
  const { isMobile, isDesktop, type } = useGetDeviceType()

  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [center] = useState<google.maps.LatLngLiteral>(getUserCoordinates() ?? {
    lat: parseFloat(process.env.REACT_APP_GOOGLE_MAPS_DEFAULT_LAT!),
    lng: parseFloat(process.env.REACT_APP_GOOGLE_MAPS_DEFAULT_LNG!)
  })
  // This is added so fetching data while dragging or changing zoom map does not 
  // cause markers to "blink" (there is no data in query while fetching)
  // With this markers do not disappear for a moment on map drag or zoom
  // This also solves problem when on switching back to map view
  // previously seen markers are not visible
  const [locations, setLocations] = useState<Location[]>([])
  useEffect(() => {
    if (!props.isFetching && map != null) {
      setLocations(props.locations)
      // Clears selected location after it is moved outside of visible bounds
      // and new data does not contain it
      if (selectedLocation != null && !props.locations.find(l => l.uuid === selectedLocation.uuid)) {
        setSelectedLocation(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isFetching, Boolean(map)])

  const containerRef = useRef()

  useGetCurrentLocation(false)

  const updateBounds = useDebouncedCallback(() => {
    if (map) {
      props.onBoundsChange(map!.getBounds() ?? null)
    }
  }, 300)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    language: 'pl',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
  })

  if (!isLoaded) {
    return (
      <Grid marginTop='10rem' width='100%' overflow='hidden'>
        <Loader />
      </Grid>
    )
  }
  
  return (
    <Grid
      sx={{
        [theme.breakpoints.up('md')]: {
          height: 'calc(100vh - 14.25rem)',
          marginBlock: '0',
          maxHeight: '40rem'
        },
        [theme.breakpoints.down('md')]: {
          height: 'calc(100vh - 14.75rem)',
          marginBottom: '.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          height: 'calc(100vh - 14.25rem)'
        }
      }}
    >
      {
        isLoaded &&
          <MapContainer ref={containerRef}>
            <GoogleMap
              mapContainerStyle={getMapStyle(type)}
              center={center}
              zoom={12}
              options={{ 
                keyboardShortcuts: false,
                fullscreenControl: false,
                clickableIcons: false,
                mapTypeControl: false,
                streetViewControl: false
              }}
              onClick={() => {
                setSelectedLocation(null)
              }}
              onLoad={(map) => { 
                setMap(map)
              }}
              onBoundsChanged={updateBounds}
            >
              {
                Boolean(map) && locations.map(p => (
                  <Marker
                    key={p.uuid}
                    position={{
                      lat: p.coordinates[1],
                      lng: p.coordinates[0]
                    }} 
                    icon={
                      selectedLocation?.uuid === p.uuid
                      ? LocationMapPinImage
                      : {
                          path: 0,
                          fillColor: theme.palette.secondary.main,
                          strokeWeight: 0,
                          fillOpacity: 1,
                          scale: 8
                        }
                    }
                    onClick={() => { setSelectedLocation(p) }}
                  >
                    {
                      selectedLocation?.uuid === p.uuid && isDesktop &&
                        <InfoBoxF
                          options={{
                            pixelOffset: new google.maps.Size(4, -20),
                            alignBottom: true
                          }}
                        >
                          <Card
                            elevation={4}
                            sx={{
                              backgroundColor: 'white',
                              borderRadius: '.5rem',
                              width: '17.5rem',
                              margin: '.5rem'
                            }}
                          >
                            <MapLocationCardView location={p}/>
                          </Card>
                        </InfoBoxF>
                    }
                  </Marker>
                ))
              }
            </GoogleMap>
          </MapContainer>
      }
      {
        !isDesktop &&
          <Popover 
            open={Boolean(selectedLocation)}
            anchorEl={containerRef.current}
            onClose={() => { setSelectedLocation(null) }}
            sx={{
              '.MuiPaper-root': {
                borderRadius: '.5rem'
              }
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            marginThreshold={isMobile ? 24 : 32}
          >
            {
              selectedLocation &&
                <Grid
                  sx={{
                    width: isMobile
                      ? 'calc(100vw - 3rem)'
                      : 'calc(100vw - 4rem)'
                  }}
                >
                  <MapLocationCardView
                    location={selectedLocation}
                  />
                </Grid>
            }
          </Popover>
      }
    </Grid>
  )
}