import { CancelRounded } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, InputAdornment, TextField, useTheme } from '@mui/material'
import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import useGetDeviceType from '../../hooks/device/useGetDeviceType'

type Props = {
  initValue?: string
  placeholder: string
  onSearch: (search: string) => void
  onClear: () => void
}

type ClearIconProps = {
  setValue: Dispatch<SetStateAction<string>>
  onClear: () => void
}

function ClearIcon (props: ClearIconProps) {
  const theme = useTheme()
  
  return (
    <InputAdornment position='end'>
      <IconButton
        onClick={() => {
          props.setValue('')
          props.onClear()
        }}
        sx={{
          padding: 0
        }}
      >
        <CancelRounded
          sx={{
            color: theme.palette.primary.main,
            '&:hover': {
              borderRadius: '50%',
              animationDuration: '1s',
              animationTimingFunction: 'ease-in-out',
              animationDelay: '0s',
              animationIterationCount: '1',
              animationName: 'rotate',
              '@keyframes rotate': {
                '100%': { transform: 'rotate(360deg)' }
              }
            }
          }}
        />
      </IconButton>
    </InputAdornment>
  )
}

export default function SearchInput(props: Props) {
  const [value, setValue] = useState<string>(props.initValue ?? '')
  const { isDesktop } = useGetDeviceType()

  useEffect(() => {
    if (props.initValue !== value) {
      setValue(props.initValue ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initValue])

  return (
    <TextField
      value={value}
      fullWidth
      variant='outlined'
      onChange={(event) => {
        setValue(event.target.value)
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault()
          if (!isDesktop) {
            ;(event.target as HTMLInputElement).blur()
          }
          props.onSearch(value)
        }
      }}
      InputProps={{
        placeholder: props.placeholder,
        endAdornment: value 
          ? <ClearIcon 
              setValue={setValue}
              onClear={props.onClear}
            />
          : null,
        startAdornment: 
          <InputAdornment position='start' sx={{height: 5}}>
            <IconButton
              onClick={() => { props.onSearch(value) }}
              sx={{
                padding: 0
              }}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
      }}
    />
  )
}
