import { Namespace, TFunction } from 'i18next'
import * as Yup from 'yup'
import { emailRegex, passwordAllowedSpecialCharactersRegex, phoneRegex } from './regexp'
import { ProviderType } from '../types/common'

export function emailFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    email: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(emailRegex, t('validations:email'))
      .max(128, t('validations:max_128_characters'))
  })
}

export function pinFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    pin: Yup.string()
      .required(t('validations:field_required'))
      .min(6, t('validations:pin_length_requirement'))
      .max(6, t('validations:pin_length_requirement'))
  })
}

export function registrationFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    firstName: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .max(64, t('validations:max_64_characters')),
    lastName: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .max(64, t('validations:max_64_characters')),
    nick: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .max(64, t('validations:max_64_characters')),
    phone: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(phoneRegex, t('validations:phone'))
      .max(128, t('validations:max_128_characters')),
    email: Yup.string()
      .when('accountType', {
        is: (accountType: ProviderType) => accountType !== ProviderType.PASSWORD,
        then: (schema) => 
          schema
            .transform((currentValue) => currentValue.trim())
            .required(t('validations:field_required'))
            .matches(emailRegex, t('validations:email'))
            .max(128, t('validations:max_128_characters'))
      }),
    password: Yup.string()
      .when('accountType', {
        is: (accountType: ProviderType) => accountType === ProviderType.PASSWORD,
        then: (schema) => 
          schema
            .required(t('validations:field_required'))
            .matches(passwordAllowedSpecialCharactersRegex, t('validations:password_special_characters'))
            .min(8, t('validations:password_min_length'))
            .max(32, t('validations:password_max_length'))
      }),
    privacyPolicy: Yup.boolean()
      .required(t('validations:field_required'))
      .oneOf([true], t('validations:field_required'))
  })
}

export function loginFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    email: Yup.string()
      .transform((currentValue) => currentValue.trim())
      .required(t('validations:field_required'))
      .matches(emailRegex, t('validations:email'))
      .max(128, t('validations:max_128_characters')),
    password: Yup.string()
      .required(t('validations:field_required'))
  })
}

export function passwordFormDataValidation(t: TFunction<Namespace>) {
  return Yup.object({
    password: Yup.string()
      .required(t('validations:field_required'))
      .matches(passwordAllowedSpecialCharactersRegex, t('validations:password_special_characters'))
      .min(8, t('validations:password_min_length'))
      .max(32, t('validations:password_max_length'))
  })
}
