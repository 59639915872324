import { Wifi, WifiOff } from '@mui/icons-material'
import { Grid, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LicenseInfo } from '@mui/x-license-pro'
import { pl } from 'date-fns/locale'
import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Toaster, toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './App.css'
import MainTheme from './components/themes/MainTheme'
import useGetAccountData from './hooks/data/account/useGetAccountData'
import Dashboard from './pages/Dashboard'
import { LoginState, forceLogOut } from './redux/storageToolkit'
import store from './redux/store'
import { getUserUuid } from './services/storage/storageService'

export const ROUTER_BASE_NAME = '/'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? '')

export default function App() {
  const texts = useTranslation(['errors', 'successes', 'common']).t
  const navigate = useNavigate()

  const loginStateRef = useRef<LoginState | null>(null)

  useGetAccountData({
    refetchInterval: parseInt(process.env.REACT_APP_ACCOUNT_DATA_REFETCH_INTERVAL!),
    enabled: store.getState().storage.loginState === LoginState.LOGGED
  })

  useEffect(() => {
    console.log(process.env.REACT_APP_VERSION)
  }, [])

  const handleLoginStateChange = () => {
    const previousValue = loginStateRef.current
    loginStateRef.current = store.getState().storage.loginState

    if (previousValue === loginStateRef.current) { //stan dla nas sie nie zmienił
      return
    }

    switch (store.getState().storage.loginState) {
      case LoginState.LOGGED:
        break
      case LoginState.FORCED_LOGGED_OUT:
        toast.error(texts('errors:force_logout'))
        navigate('/login')
        break
      case LoginState.LOGGED_OUT:
        toast.success(texts('successes:logout'))
        navigate('/login')
        break
    }
  }

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      handleLoginStateChange()
    })

    const handleCrossTabLogout = () => {
      if (store.getState().storage.loginState === LoginState.LOGGED && !getUserUuid()) {
        store.dispatch(forceLogOut())
      }
    }

    const detectOnline = () => {
      toast(
        texts('common:online'),
        {
          icon: <Wifi sx={{ color: MainTheme.palette.success.main }} />
        }
      )
    }

    const detectOffline = () => {
      toast(
        texts('common:offline'),
        {
          icon: <WifiOff sx={{ color: MainTheme.palette.error.main }} />
        }
      )
    }
    window.addEventListener('storage', handleCrossTabLogout) 
    window.addEventListener('online', detectOnline)
    window.addEventListener('offline', detectOffline)

    return () => {
      window.removeEventListener('storage', handleCrossTabLogout)
      window.removeEventListener('online', detectOnline)
      window.removeEventListener('offline', detectOffline)
      unsubscribe()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={MainTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
        <Helmet>
          <meta key='robots' name='robots' content='noindex,nofollow' />
          <meta key='googlebot' name='googlebot' content='noindex,nofollow' />
          {
            store.getState().storage.loginState === LoginState.LOGGED &&
            <style>{'body { background-color: #fafafa; }'}</style>
          }
        </Helmet>
        <Grid 
          sx={{
            '& .toast': {
              'div:first-child': {
                marginTop: '.25rem'
              }
            }
          }}
        >
          <Toaster
            position='top-right'
            containerStyle={{
              fontFamily:'Roboto',
              top:'5rem'
            }}
            toastOptions={{
              className: 'toast',
              style: {
                alignItems: 'flex-start'
              },
              success: { duration: 4000 },
              error: { duration: 4000 },
              custom: { duration: 4000 }
            }}
          />
        </Grid>
        <Dashboard/>
      </LocalizationProvider>
    </ThemeProvider>
  )
}