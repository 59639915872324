import { ReservationStatus } from '../../types/reservations'
import { ReservationStatusColor } from '../../utils/const'

export function mapReservationStatusToColor(status: ReservationStatus): ReservationStatusColor {
  switch (status) {
    case ReservationStatus.CANCELED:
      return ReservationStatusColor.CANCELED
    case ReservationStatus.FINISHED:
      return ReservationStatusColor.FINISHED
    default:
      return ReservationStatusColor.UNDEFINED
  }
}