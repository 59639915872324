import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AuthDto } from '../api/auth/types'
import { AccountDto, UpdatePackageDto } from '../api/types'
import { mapFullName } from '../mapping/common'
import { mapAuthDto } from '../mapping/storage'
import { clearUserCache, getUserData, getUserName, getUserUuid, setAuthData, setPackageData, setUserData, setUserName } from '../services/storage/storageService'
import { Account as StorageAccount } from '../types/storage'

export enum LoginState {
  LOGGED,
  LOGGED_OUT,
  FORCED_LOGGED_OUT
}

export type StorageState = {
  loginState: LoginState,
  userName: string
  userData: StorageAccount | null
}

const defaultState: StorageState = {
  loginState: getUserUuid() != null ? LoginState.LOGGED : LoginState.LOGGED_OUT,
  userName: getUserName() ?? '',
  userData: getUserData() ?? null
}

const slice = createSlice({
  name: 'storage',
  initialState: defaultState,
  reducers: {
    logIn: (state, action: PayloadAction<AuthDto>) => {
      setAuthData(action.payload)
      state.loginState = LoginState.LOGGED
      state.userName = mapFullName(action.payload.user.firstName, action.payload.user.lastName)
      state.userData = mapAuthDto(action.payload)
    },
    logOut: state => {
      clearUserCache()
      state.loginState = LoginState.LOGGED_OUT
    },
    forceLogOut: state => {
      clearUserCache()
      state.loginState = LoginState.FORCED_LOGGED_OUT
    },
    updateUserData: (state, action: PayloadAction<AccountDto>) => {
      setUserData(action.payload)
      state.userData = action.payload
    },
    updateUserName: (state, action: PayloadAction<string>) => {
      setUserName(action.payload)
      state.userName = action.payload
    },
    updatePackageData: (state, action: PayloadAction<UpdatePackageDto>) => {
      setPackageData(action.payload)
      state.userData = getUserData()
    }
  }
})

export const {
  logIn,
  logOut,
  forceLogOut,
  updateUserName,
  updateUserData,
  updatePackageData
} = slice.actions

export default slice.reducer