import { Session } from '../api/data/types'
import { SessionData } from '../types/session'
import { mapListEntryDto } from './reservation'

export function mapSessionDataDto(data: Session.SessionDataDto): SessionData {
  return {
    counts: data.counts,
    trainees: data.trainees,
    account: data.account,
    reservations: data.reservations.map(mapListEntryDto)
  }
}