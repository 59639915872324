import { CalendarMonthOutlined, ConfirmationNumberOutlined } from '@mui/icons-material'
import { Button, Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next'
import InfoBlock from './InfoBlock'
import { Package } from '../../../types/account'
import BuyPackageDialog from '../../dialogs/package/BuyPackageDialog'
import { useState } from 'react'

type Props = {
  isDemo: boolean
  package: Package | null
}

export default function PackageCardView(props: Props) {
  const theme = useTheme()
  const texts = useTranslation(['common', 'profileSettings']).t

  const [isBuyPackageDialogOpen, setIsBuyPackageDialogOpen] = useState<boolean>(false)

  return (
    <>
      <BuyPackageDialog
        demo={props.isDemo}
        open={isBuyPackageDialogOpen}
        onClose={() => { setIsBuyPackageDialogOpen(false) }}
      />
      <Card sx={{ width: '100%' }}>
        <CardContent
          sx={{
            [theme.breakpoints.up('md')]: {
              padding: '2rem',
              ':last-child': {
                padding: '2rem'
              }
            },
            [theme.breakpoints.down('md')]: {
              padding: '1rem',
              ':last-child': {
                padding: '1rem'
              }
            }
          }}
        >
          <Typography variant='h4' color='#BBBBBB'>
            {texts('profileSettings:active_package_section_title')}
          </Typography>
          <Grid 
            container
            flexDirection='column'
            alignItems='center'
          >
            {
              props.package != null
              ? <Grid
                  sx={{
                    [theme.breakpoints.up('sm')]: {
                      width: '80%'
                    },
                    [theme.breakpoints.down('sm')]: {
                      width: '100%'
                    }
                  }}
                >
                  <InfoBlock icon={ConfirmationNumberOutlined}>
                    <Grid 
                      gridArea='1 / 2 / 2 / 3'
                      container
                      alignItems='center'
                    >
                      <Typography 
                        variant='body1'
                        fontWeight={700}
                      >
                        {texts('profileSettings:entries_left')}
                      </Typography>
                    </Grid>
                    <Grid gridArea='2 / 2 / 3 / 3'>
                      <Typography 
                        variant='body1'
                      >
                        {`${props.package.leftEntries}/${props.package.totalEntries}`}
                      </Typography>
                      {
                        props.package.leftEntries === 0 &&
                          <Typography
                            variant='body1'
                            sx={{
                              marginTop: '.5rem',
                              color: theme.palette.error.main
                            }}
                          >
                            <Trans i18nKey='profileSettings:warning'/>
                          </Typography>
                      }
                    </Grid>
                  </InfoBlock>
                  <InfoBlock icon={CalendarMonthOutlined}>
                    <Grid 
                      gridArea='1 / 2 / 2 / 3'
                      container
                      alignItems='center'
                    >
                      <Typography 
                        variant='body1'
                        fontWeight={700}
                      >
                        {texts('profileSettings:package_validity')}
                      </Typography>
                    </Grid>
                    <Grid gridArea='2 / 2 / 3 / 3'>
                      <Typography variant='body1'>
                        {texts('profileSettings:to_label', { value: moment(props.package.validUntil).format('DD.MM.YYYY') })}
                      </Typography>
                    </Grid>
                  </InfoBlock>
                </Grid>
              : <Grid 
                  container
                  flexDirection='column'
                  width='100%'
                  alignItems='flex-end'
                >
                  <Grid
                    sx={{
                      width: '100%',
                      paddingBlock: '1rem',
                      textAlign: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#BBBBBB'
                      }}
                    >
                      {texts('profileSettings:no_active_package')}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Button
                      variant='contained'
                      size='medium'
                      sx={{
                        width: '15rem',
                        height: '2rem !important',
                        [theme.breakpoints.up('md')]: {
                          marginBottom: '0',
                          marginRight: '0'
                        },
                        [theme.breakpoints.down('md')]: {
                          marginBottom: '1rem',
                          marginRight: '1rem'
                        },
                        [theme.breakpoints.down('sm')]: {
                          marginBottom: '1rem',
                          marginRight: '0'
                        }
                      }}
                      onClick={() => { setIsBuyPackageDialogOpen(true) }}
                    >
                      {texts('profileSettings:buy_package')}
                    </Button>
                  </Grid>
                </Grid>
            }
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}