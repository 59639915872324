import { Add } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment-timezone'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import HidingFab from '../../components/customMui/HidingFab'
import Loader from '../../components/customMui/Loader'
import ControlSection from '../../components/reservations/list/ControlSection'
import MainSection from '../../components/reservations/list/MainSection'
import TopSection from '../../components/reservations/list/TopSection'
import useGetSelectedReservationPlaces from '../../hooks/data/locations/useGetSelectedReservationPlaces'
import useGetReservationList from '../../hooks/data/reservations/useGetReservationList'
import useGetSelectedReservationTrainees from '../../hooks/data/trainees/useGetSelectedReservationTrainees'
import useGetDeviceType from '../../hooks/device/useGetDeviceType'
import { queryNames } from '../../hooks/queries'
import { RadioButtonOption } from '../../types/common'
import { ReservationListSearchParams } from '../../types/reservations'
import ConnectionError from '../Errors/ConnectionError'

export const reservationsPerPage = Number.parseInt(process.env.REACT_APP_RESERVATIONS_PER_PAGE!)

export function isSearchParamsValid(params: DecodedValueMap<ReservationListSearchParams>,sortOptions: RadioButtonOption[]): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
    && (
      (!params.from && !params.to) // date range not set
      || (moment(params.from, 'DD.MM.YYYY').isValid() && moment(params.to, 'DD.MM.YYYY').isValid()) //date range set with valid moment strings
    )
    && sortOptions.find(o => o.value === params.order) != null // valid order option
}

export default function ReservationList() {
  const texts = useTranslation('reservationList').t
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isMobile } = useGetDeviceType()

  const sortOptions: RadioButtonOption[] = useMemo(() => {
    return texts('sort_options', {
      returnObjects: true 
    }) as RadioButtonOption[]
  }, [texts])
  
  const [searchParams, setSearchParams] = useQueryParams<ReservationListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    from: withDefault(StringParam, undefined),
    to: withDefault(StringParam, undefined),
    locations: withDefault(StringParam, undefined),
    rooms: withDefault(StringParam, undefined),
    trainees: withDefault(StringParam, undefined),
    order: withDefault(StringParam, sortOptions[0].value)
  })

  const selectedPlaces = useGetSelectedReservationPlaces(
    searchParams.locations != null ? searchParams.locations.split(',')  : [],
    (data) => {
      if (
        data.length !== searchParams.locations!.split(',').length
        || (searchParams.rooms != null && searchParams.rooms.split(',').length !== data.map(e => e.trainingRooms).flat().filter(e => searchParams.rooms?.split(',').includes(e.uuid)).length)
      ) {
        setSearchParams({
          page: 1,
          from: undefined,
          to: undefined,
          locations: undefined,
          rooms: undefined,
          trainees: undefined
        })
      }
    },
    searchParams.locations != null && isSearchParamsValid(searchParams, sortOptions)
  )

  const selectedTrainees = useGetSelectedReservationTrainees(
    searchParams.trainees != null ? searchParams.trainees.split(',')  : [],
    (data) => {
      if (data.length !== searchParams.trainees!.split(',').length) {
        setSearchParams({
          page: 1,
          from: undefined,
          to: undefined,
          locations: undefined,
          rooms: undefined,
          trainees: undefined
        })
      }
    },
    searchParams.trainees != null && !selectedPlaces.isFetching && isSearchParamsValid(searchParams, sortOptions)
  )

  const reservationList = useGetReservationList({
    offset: (searchParams.page - 1) * reservationsPerPage,
    limit: reservationsPerPage,
    enabled: !selectedPlaces.isFetching && !selectedTrainees.isFetching && isSearchParamsValid(searchParams, sortOptions),
    order: searchParams.order,
    ...(searchParams.from ? { from: moment(searchParams.from, 'DD.MM.YYYY').format('YYYY-MM-DD') } : {}),
    ...(searchParams.to ? { to: moment(searchParams.to, 'DD.MM.YYYY').format('YYYY-MM-DD') } : {}),
    ...(searchParams.locations ? { places: searchParams.locations } : {}),
    ...(searchParams.rooms ? { rooms: searchParams.rooms } : {}),
    ...(searchParams.trainees ? { trainees: searchParams.trainees } : {}),
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.reservations.length === 0) {
        setSearchParams({
          page: Math.ceil(data.totalCount / reservationsPerPage),
        }, 'replaceIn')
      }
    }
  })

  useEffect(() => {
    if (!isSearchParamsValid(searchParams, sortOptions)) {
      setSearchParams({
        page: 1,
        from: undefined,
        to: undefined,
        order: sortOptions[0].value
      }, 'replaceIn')
      return
    }
    
    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (reservationList.isError || selectedPlaces.isError || selectedTrainees.isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          if (reservationList.isError) {
            queryClient.resetQueries([
              queryNames.getReservationList
            ])
          }
          if (selectedPlaces.isError) {
            queryClient.resetQueries([
              queryNames.getSelectedPlaces
            ])
          }
          if (selectedTrainees.isError) {
            queryClient.resetQueries([
              queryNames.getSelectedTrainees
            ])
          }
        }}
      />
    )
  }

  if (selectedPlaces.isFetching || selectedTrainees.isFetching) {
    return (
      <Grid marginTop='10rem' width='100%' overflow='hidden'>
        <Loader />
      </Grid>    
    )
  }

  if (reservationList.isFetching) {
    return (
      <Grid
        minWidth='100%'
        minHeight='100%'
      >
        <Grid width='100%' paddingX='1rem'>
          <TopSection />
          <ControlSection 
            selectedLocations={selectedPlaces.data ?? []}
            selectedTrainees={selectedTrainees.data ?? []}
          />
          <Grid marginTop='10rem' width='100%' overflow='hidden'>
            <Loader />
          </Grid> 
        </Grid>
      </Grid>   
    )
  }

  const isPageNotFound = searchParams.page > 1 && reservationList.data!.reservations.length === 0
  if (!isSearchParamsValid(searchParams, sortOptions) || isPageNotFound) {
    return <></>
  }

  return (
    <>
      {
        isMobile && 
          <HidingFab onClick={() => { navigate('/locations')}}>
            <Add />
          </HidingFab>
      }
      <Grid
        minWidth='100%'
        minHeight='100%'
      >
        <Grid width='100%' paddingX='1rem'>
          <TopSection />
          <ControlSection 
            selectedLocations={selectedPlaces.data ?? []}
            selectedTrainees={selectedTrainees.data ?? []}
          />
          <MainSection 
            data={reservationList.data!}
          />
        </Grid>
      </Grid>
    </>
    
  )
}