import styled from '@emotion/styled'
import { Box, Grid, useTheme } from '@mui/material'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { useRef, useState } from 'react'
import LocationMapPinImage from '../../../assets/images/locationMapPin.svg'
import useGetDeviceType, { Device } from '../../../hooks/device/useGetDeviceType'
import { Coordinates } from '../../../types/common'

type Props = {
  coordinates: Coordinates
}

const MapContainer = styled(Box)({
  img: {
    position: 'absolute'
  }
})

function getMapStyle(deviceType: Device) {
  switch (deviceType) {
    case Device.DESKTOP:
      return {
        height: '10rem'
      }
    case Device.TABLET:
      return {
        height: 'calc(100vh - 27rem)'
      }
    case Device.MOBILE:
      return {
        height: 'calc(100vh - 23rem)'
      }
  }
}

export default function MapSection(props: Props) {
  const theme = useTheme()

  const device = useGetDeviceType()

  const [center] = useState<google.maps.LatLngLiteral>(props.coordinates)

  const containerRef = useRef()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    language: 'pl',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
  })

  return (
    <Grid
      sx={{
        [theme.breakpoints.up('md')]: {
          height: '10rem',
          paddingBlock: '0'
        },
        [theme.breakpoints.down('md')]: {
          height: 'calc(100vh - 27rem)',
          paddingBlock: '.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          height: 'calc(100vh - 23rem)',
          marginTop: '1rem'
        },
      }}
    >
      {
        isLoaded &&
          <MapContainer ref={containerRef}>
            <GoogleMap
              mapContainerStyle={getMapStyle(device.type)}
              center={center}
              zoom={15}
              options={{ 
                keyboardShortcuts: false,
                fullscreenControl: false,
                clickableIcons: false,
                mapTypeControl: false,
                streetViewControl: false
              }}
            >
              <MarkerF
                position={props.coordinates} 
                icon={LocationMapPinImage}
              />
            </GoogleMap>
          </MapContainer>
      }
    </Grid>
  )
}