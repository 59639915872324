import { Grid, useTheme } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import TopSection from '../../components/payments/product/TopSection'
import MainSection from '../../components/payments/product/MainSection'
import ConnectionError from '../Errors/ConnectionError'
import { useQueryClient } from '@tanstack/react-query'
import { queryNames } from '../../hooks/queries'
import Loader from '../../components/customMui/Loader'
import useGetTrainerProductDetails from '../../hooks/data/product/useGetTrainerProductDetails'
import { isDataError } from '../../services/errors/utils'
import { DataSubcodes } from '../../services/errors/consts'
import { mapProductDetailsDtoToForm } from '../../mapping/product'

export default function ProductPage() {
  const theme = useTheme()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  const isEditPage = Boolean(uuid)

  const details = useGetTrainerProductDetails({
    enabled: isEditPage,
    uuid: uuid ?? '',
    onError: (error) => {
      if (isDataError(error, DataSubcodes.PRODUCT_NOT_FOUND)) {
        navigate(-1)
      }
    }
  })

  if (details.isFetching) {
    return (
      <Grid marginTop='10rem' width='100%' overflow='hidden'>
        <Loader />
      </Grid>
    )
  }

  if (details.isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          queryClient.resetQueries([queryNames.getTrainerProductDetails, uuid])
        }}
      />
    )
  }

  return (
    <Grid
      width='100%'
      paddingX='1rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingBlock: '2rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingBlock: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '1.5rem'
        }
      }}
    >
      <TopSection isEditPage={isEditPage} />
      <MainSection 
        isEditPage={isEditPage} 
        initialValues={
          isEditPage
            ? mapProductDetailsDtoToForm(details.data!)
            : undefined
          }
        
      />
    </Grid>
  )
}