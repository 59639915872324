import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import AuthApi from '../../api/auth/AuthApi'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/errors'
import store from '../../redux/store'
import { logOut } from '../../redux/storageToolkit'

const authApi = AuthApi.getInstance()

export function useSignout(onSuccess: () => void) {
  const texts = useTranslation().t

  const mutation = useMutation(() => authApi.signout(), {
    onSuccess: () => {
      onSuccess()
      store.dispatch(logOut())
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}