export enum Codes {
  AUTH = 100,
  DATA = 200,
  INTERNAL = 999
}

export enum AuthSubcodes {
  INVALID_CREDENTIALS = 100,
  UNAUTHORIZED = 101,
  USER_INACTIVE = 102,
  ACCOUNT_REMOVED = 103,
  INVALID_TOKEN = 104,
  INCORRECT_PWD = 105,
  SAME_PWD_AS_PREVIOUS_ONE = 106,
  TOKEN_ALREADY_USED = 107,
  FORBIDDEN = 108,
  EMAIL_TAKEN = 109,
  INVALID_TOKEN_OR_USED = 110,
  USER_NOT_FOUND = 111,
  USER_ALREADY_EXISTS = 112,
  EMAIL_NOT_VERIFIED = 113,
  INCORRECT_CODE = 114,
  INVALID_CODE = 115,
  CODE_ALREADY_USED = 116,
  OAUTH_TOKEN_NOT_VALID = 117,
  INVALID_CODE_OR_USED = 118,
  SOCIAL_ACCOUNT_NOT_AVAILABLE = 119
}

export enum DataSubcodes {
  BAD_REQUEST = 200,
  PLACE_NOT_FOUND = 203,
  TRAINEE_NOT_FOUND = 207,
  MAX_TIME_RANGE_EXCEEDED = 227,
  EVENT_NOT_FOUND = 230,
  EMAIL_TAKEN = 231,
  RESERVATION_NOT_FOUND = 236,
  NO_EMPTY_SEATS = 238,
  EVENT_ALREADY_STARTED = 239,
  TRAINEE_NOT_LINKED_TO_TRAINER = 242,
  NO_DEMO_ENTRIES_LEFT = 243,
  PRODUCT_NOT_FOUND = 244,
  NO_ACTIVE_TRAINEE_PACKAGE = 250,
  PLACE_INACTIVE = 252,
  TRAINING_ROOM_INACTIVE = 253,
  NO_TRAINEE_ENTRIES_LEFT = 254,
  EVENT_AFTER_RESERVATION_WINDOW = 255
}

export enum InternalSubcodes {
  FORCED_LOGOUT = 100
}