import { CallOutlined, EmailOutlined, LocationOnOutlined, NavigationOutlined } from '@mui/icons-material'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import { LocationDetails } from '../../../types/locations'
import { evalStringTemplate } from '../../../utils/text'
import Link from '../../customMui/Link'
import InfoBlock from './InfoBlock'
import MapSection from './MapSection'

type Props = {
  location: LocationDetails
}

export default function InfoCardViewContent(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('locationDetails').t

  const { isDesktop } = useGetDeviceType()

  return (
    <Grid
      container
      flexDirection='column'
      sx={{
        [theme.breakpoints.up('sm')]: {
          gap: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          gap: '1rem'
        }
      }}
    >
      <Grid>
        <Typography
          variant={isDesktop ? 'h5' : 'h4'}
          color='#BBBBBB'
        >
          {texts('location_section_title')}
        </Typography>
        <InfoBlock icon={LocationOnOutlined}>
          <Grid 
            gridArea='1 / 2 / 2 / 3'
            container
            alignItems='center'
          >
            <Typography variant='body1'>
              {texts(
                'address_label',
                {
                  streetAddress: props.location.address.streetAddress,
                  postalCode: props.location.address.postalCode,
                  town: props.location.address.town
                }
              )}
            </Typography>
          </Grid>
          <Grid gridArea='2 / 2 / 3 / 3'>
            <Button 
              variant='text'
              onClick={() => {
                window.open(
                  evalStringTemplate(
                    texts('navigate_url'), 
                    {
                      lat: props.location.coordinates[1],
                      lng: props.location.coordinates[0]
                    }
                  ),
                  '_blank'
                )
              }}
            >
              <Grid
                container
                alignItems='center'
              >
                <NavigationOutlined 
                  sx={{
                    fontSize: '1.125rem'
                  }}
                />
                {texts('navigate_button_label')}
              </Grid>
            </Button>
          </Grid>
        </InfoBlock>
      </Grid>
      <Grid>
        <Typography
          variant={isDesktop ? 'h5' : 'h4'}
          color='#BBBBBB'
        >
          {texts('phone_section_title')}
        </Typography>
        <InfoBlock icon={CallOutlined}>
          <Grid 
            gridArea='1 / 2 / 2 / 3'
            container
            alignItems='center'
          >
            <Typography variant='body1'>
              <Link to={`tel:${props.location.contact.phone}`}> 
                {props.location.contact.phone}
              </Link>
            </Typography>
          </Grid>
        </InfoBlock>
      </Grid>
      <Grid>
        <Typography
          variant={isDesktop ? 'h5' : 'h4'}
          color='#BBBBBB'
        >
          {texts('email_section_title')}
        </Typography>
        <InfoBlock icon={EmailOutlined}>
          <Grid 
            gridArea='1 / 2 / 2 / 3'
            container
            alignItems='center'
          >
            <Typography variant='body1'>
              <Link to={`mailto:${props.location.contact.email}`}> 
                {props.location.contact.email}
              </Link>
            </Typography>
          </Grid>
        </InfoBlock>
      </Grid>
      <Grid>
        <Typography
          variant={isDesktop ? 'h5' : 'h4'}
          color='#BBBBBB'
        >
          {texts('navigate_section_title')}
        </Typography>
        <Grid
          sx={{
            [theme.breakpoints.up('sm')]: {
              marginTop: '1rem',
            },
            [theme.breakpoints.down('sm')]: {
              marginTop: '.75rem',
            },
          }}
        >
          <MapSection 
            coordinates={{
              lat: props.location.coordinates[1],
              lng: props.location.coordinates[0]
            }} 
          />
        </Grid>
      </Grid>
    </Grid>
  )
}