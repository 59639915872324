import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { Common } from '../../../api/data/types'
import { mapListDto } from '../../../mapping/product'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

export default function useGetProductList(options: Common.ListOptionsDto) {
  const texts = useTranslation().t
  const key = [
    queryNames.getProductList,
    options.limit,
    options.offset
  ]
  const query = useQuery(key, async () => {
    const { data } = await dataApi.getProductList(options)
    return mapListDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
