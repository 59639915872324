import { EmailDto, LoginDto, RegistrationDto, GoogleRegistrationDto, FacebookRegistrationDto, AppleRegistrationDto, VerifyEmailDto, PasswordDto } from '../api/auth/types'
import { EmailFormData, PasswordFormData, PinFormData, LoginFormData, RegistrationFormData } from '../types/form/auth'

export function mapEmailFormData(data: EmailFormData): EmailDto {
  return {
    email: data.email.trim()
  }
}

export function mapLoginFormData(data: LoginFormData): LoginDto {
  return {
    login: data.email.trim(),
    password: data.password
  }
}

export function mapRegistrationFormData(data: RegistrationFormData): RegistrationDto {
  return {
    email: data.email.trim(),
    password: data.password,
    firstName: data.firstName.trim(),
    lastName: data.lastName.trim(),
    phone: data.phone.trim(),
    newsletter: data.newsletter,
    ...(data.nick.trim() ? { nick: data.nick.trim() } : {})
  }
}

export function mapAppleRegistrationFormData(data: RegistrationFormData, token: string, nonce: string): AppleRegistrationDto {
  return {
    token: token,
    nonce: nonce,
    firstName: data.firstName.trim(),
    lastName: data.lastName.trim(),
    phone: data.phone.trim(),
    newsletter: data.newsletter,
    contactEmail: data.email.trim(),
    ...(data.nick.trim() ? { nick: data.nick.trim() } : {})
  }
}

export function mapFacebookRegistrationFormData(data: RegistrationFormData, token: string): FacebookRegistrationDto {
  return {
    token: token,
    firstName: data.firstName.trim(),
    lastName: data.lastName.trim(),
    phone: data.phone.trim(),
    newsletter: data.newsletter,
    contactEmail: data.email.trim(),
    ...(data.nick.trim() ? { nick: data.nick.trim() } : {})
  }
}

export function mapGoogleRegistrationFormData(data: RegistrationFormData, token: string): GoogleRegistrationDto {
  return {
    token: token,
    firstName: data.firstName.trim(),
    lastName: data.lastName.trim(),
    phone: data.phone.trim(),
    newsletter: data.newsletter,
    contactEmail: data.email.trim(),
    ...(data.nick.trim() ? { nick: data.nick.trim() } : {})
  }
}

export function mapPinFormData(data: PinFormData, email: string): VerifyEmailDto {
  return {
    email: email.trim(),
    code: data.pin
  }
}

export function mapPasswordFormData(data: PasswordFormData): PasswordDto {
  return {
    ...data
  }
}