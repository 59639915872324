import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../components/customMui/Loader'
import MainSection from '../../components/reservations/details/MainSection'
import TopSection from '../../components/reservations/details/TopSection'
import useGetReservationDetails from '../../hooks/data/reservations/useGetReservationDetails'
import { queryNames } from '../../hooks/queries'
import { DataSubcodes } from '../../services/errors/consts'
import { isDataError } from '../../services/errors/utils'
import ConnectionError from '../Errors/ConnectionError'

export default function ReservationDetails() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  const reservationDetails = useGetReservationDetails(
    uuid ?? '',
    (error) => {
      if (isDataError(error, DataSubcodes.RESERVATION_NOT_FOUND)) {
        navigate('/404')
      }
    }
  )

  if (reservationDetails.isFetching) {
    return (
      <Grid marginTop='10rem' width='100%' overflow='hidden'>
        <Loader />
      </Grid>
    )  
  }

  if (reservationDetails.isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          queryClient.resetQueries([queryNames.getReservationDetails, uuid])
        }}
      />
    )
  }

  return (
    <Grid width='100%' paddingX='1rem'>
      <TopSection reservation={reservationDetails.data!} />
      <MainSection reservation={reservationDetails.data!} />
    </Grid>
  )
}