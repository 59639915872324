import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { CardActions, IconButton, Menu, MenuItem, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Reservation } from '../../../types/reservations'
import { ReservationStatus } from '../../../types/reservations'
import { useNavigate } from 'react-router-dom'

type Props = {
  reservation: Reservation
  onCancel: () => void
}

export default function ReservationCardViewActions(props: Props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const texts = useTranslation('reservationList').t
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <CardActions
      sx={{
        position: 'absolute',
        top: 16,
        right: 16
      }}
    >
      <IconButton
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        sx={{
          color: '#000',
          padding: 0
        }}
      >
        <MoreHorizIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '12rem',
          },
        }}
      >
        {
          (props.reservation.status === ReservationStatus.CANCELED || props.reservation.status === ReservationStatus.FINISHED) &&
            <MenuItem 
              onClick={() => {
                navigate(`/locations/${props.reservation.place.uuid}/trainingroom/${props.reservation.trainingRoom.uuid}/calendar`)
              }}
            >
              {texts('reserve_again_tile_menu_label')}
            </MenuItem>
        }
        {
          props.reservation.status === ReservationStatus.ACTIVE &&
            <MenuItem 
              onClick={() => {
                handleClose()
                props.onCancel()
              }}
              sx={{
                color: theme.palette.error.main
              }}
            >
              {texts('cancel_reservation_tile_menu_label')}
            </MenuItem>
        }
      </Menu>
    </CardActions>
  )
}