import { LocationOnOutlined } from '@mui/icons-material'
import { Button, Card, CardActions, CardContent, Grid, Skeleton, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom'
import LocationPlaceholderImage from '../../../assets/images/locationPlaceholder.webp'
import { mapDistanceValue } from '../../../mapping/common'
import { Location } from '../../../types/locations'

type Props = {
  location: Location
}

export default function LocationCardView(props: Props) {
  const texts = useTranslation('locationList').t
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Card sx={{ overflow: 'hidden', maxWidth: '100%' }}>
      <CardContent sx={{ padding: 0 }}>
        <Grid 
          sx={{ 
            position: 'relative',
            fontSize: 0,
            width: '100%',
            height: 'auto',
            aspectRatio: '1.78',
          }}
        >
          <Grid sx={{ paddingBottom: '56.16%' }} />
          <LazyLoadImage
            src={props.location.photo?.thumbnail ?? props.location.photo?.original ?? LocationPlaceholderImage}
            placeholder={          
              <Skeleton
                variant='rectangular'
                width='100%'
                height='100%'
                sx={{
                  position: 'absolute',
                  inset: 0,
                  borderTopLeftRadius: '.5rem',
                  borderTopRightRadius: '.5rem'
                }}
              />
            }
            style={{
              width: '100%',
              height: '100%',
              borderTopLeftRadius: '.5rem',
              borderTopRightRadius: '.5rem',
              position: 'absolute',
              inset: 0
            }}
          />
          {
            props.location.upcomingEvent != null &&
              <Grid
                sx={{
                  position: 'absolute',
                  top: 8,
                  left: 8,
                  backgroundColor: 'rgba(0 0 0 / 0.8)',
                  paddingBlock: '.5rem',
                  paddingInline: '1rem',
                  borderRadius: '.25rem',
                }}
              >
                <Typography 
                  variant='badge'
                  sx={{
                    color: '#fff'
                  }}
                >
                  {texts(
                    'available_at',
                    {
                      date: texts(
                        'tile_date_label',
                        {
                          diff: moment(props.location.upcomingEvent.scheduledAt, 'YYYY-MM-DD').diff(moment().startOf('d'), 'd'),
                          date: moment(props.location.upcomingEvent.scheduledAt, 'YYYY-MM-DD').format('DD.MM.YYYY')
                        }
                      ),
                      time: props.location.upcomingEvent.startTime
                    }
                  )}
                </Typography>
              </Grid>
          }
          {
            props.location.distance != null &&
              <Grid
                container
                alignItems='center'
                sx={{
                  position: 'absolute',
                  right: 8,
                  bottom: 8,
                  backgroundColor: 'rgba(92 15 139 / 0.8)',
                  padding: '.25rem',
                  width: 'max-content',
                  color: '#fff !important',
                  borderRadius: '.25rem'
                }}
              >
                <LocationOnOutlined 
                  sx={{
                    fontSize: '1rem',
                    marginTop: '-.125rem'
                  }}
                />
                <Typography variant='body2'>
                  {mapDistanceValue(props.location.distance)}
                </Typography>
              </Grid>
          }
        </Grid>
        <Grid
          container
          flexDirection='column'
          gap='.5rem'
          padding='1rem'
        >
          <Typography
            variant='body1'
            fontWeight={700}
            sx={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              overflow: 'hidden'
            }}
          >
            {props.location.name}
          </Typography>
          <Typography 
            variant='body1'
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 'var(--max-lines)',
              WebkitBoxOrient: 'vertical',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              overflow: 'hidden',
              visibility: 'visible',
              height: 'calc(var(--lh) * var(--max-lines))',
              maxHeight: 'calc(var(--lh) * var(--max-lines))',
              [theme.breakpoints.up('sm')]: {
                '--max-lines': 2
              },
              [theme.breakpoints.down('sm')]: {
                '--max-lines': 1
              },
            }}
          >
            {texts(
              'address_label',
              {
                streetAddress: props.location.address.streetAddress,
                postalCode: props.location.address.postalCode,
                town: props.location.address.town
              }
            )}
          </Typography>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          justifyContent='flex-end'
          gap='1rem'
          sx={{
            paddingInline: '1rem',
            paddingBottom: '1rem',
            paddingTop: '.5rem'
          }}
        >
          <Button
            onClick={() => {
              navigate(`/locations/${props.location.uuid}/calendar`)
            }}
          >
            {texts('reserve_button')}
          </Button>
          <Button
            onClick={() => {
              navigate(`/locations/${props.location.uuid}`)
            }}
            sx={{
              color: '#66C4D6'
            }}
          >
            {texts('details_button')}
          </Button>
        </Grid>
      </CardActions>
    </Card>
  )
}