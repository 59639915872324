import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { CardActions, IconButton, Menu, MenuItem, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Product } from '../../../types/product'
import { useNavigate } from 'react-router-dom'

type Props = {
  product: Product
  onRemove: () => void
}

export default function ProductCardViewActions(props: Props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const texts = useTranslation('paymentsConfiguration').t
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <CardActions
      sx={{
        position: 'absolute',
        top: 16,
        right: 16
      }}
    >
      <IconButton
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        sx={{
          color: '#000',
          padding: 0
        }}
      >
        <MoreHorizIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '12rem',
          },
        }}
      >
        <MenuItem 
          onClick={() => {
            handleClose()
            navigate(`/payments/product/${props.product.uuid}/edit`)
          }}
        >
          {texts('edit_product')}
        </MenuItem>
        <MenuItem 
          onClick={() => {
            handleClose()
            props.onRemove()
          }}
          sx={{
            color: theme.palette.error.main
          }}
        >
          {texts('remove_product')}
        </MenuItem>
      </Menu>
    </CardActions>
  )
}