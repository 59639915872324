import { Navigate, Route } from 'react-router'
import { Outlet, Routes } from 'react-router-dom'
import MainOverlay from '../components/overlays/MainOverlay'
import { LoginState } from '../redux/storageToolkit'
import store from '../redux/store'
import Login from './Auth/Login'
import Registration from './Auth/Registration'
import ResetPassword from './Auth/ResetPassword'
import NotFoundError from './Errors/NotFoundError'
import LocationDetails from './Location/LocationDetails'
import LocationEventsCalendar from './Location/LocationEventsCalendar'
import LocationList from './Location/LocationList'
import RoomEventsCalendar from './Location/RoomEventsCalendar'
import ProfileSettings from './ProfileSettings'
import ReservationDetails from './Reservations/ReservationDetails'
import ReservationList from './Reservations/ReservationList'
import SummaryPanel from './SummaryPanel'
import InviteTrainee from './Trainee/InviteTrainee'
import TraineeDetails from './Trainee/TraineeDetails'
import TraineeList from './Trainee/TraineeList'
import PaymentsConfiguration from './Payments/PaymentsConfiguration'
import ProductPage from './Payments/ProductPage'

export default function Dashboard() {
  const sessionRoutes = () => {
    return (
      <>
        <Route path='/' element={<Outlet />}>
          <Route path='registration' element={<Navigate to='/' />} />
          <Route path='login' element={<Navigate to='/' />} />
          <Route path='password' element={<Navigate to='/' />}>
            <Route path='reset' element={<Navigate to='/' />} />
          </Route>
          <Route index element={<SummaryPanel />} />
          <Route path='reservations' element={<Outlet />} >
            <Route index element={<ReservationList />} />
            <Route path=':uuid/details' element={<ReservationDetails />} />
          </Route>
          <Route path='locations' element={<Outlet />} >
            <Route index element={<LocationList />} />
            <Route path=':uuid' element={<LocationDetails />} />
            <Route path=':uuid/trainingroom/:roomUuid/calendar' element={<RoomEventsCalendar />} />
            <Route path=':uuid/calendar' element={<LocationEventsCalendar />} />
          </Route>
          <Route path='trainees' element={<Outlet />} >
            <Route index element={<TraineeList />} />
            <Route path=':uuid' element={<TraineeDetails />} />
            <Route path=':traineeUuid/reservations/:uuid/details' element={<ReservationDetails />} />
            <Route path='invite' element={<InviteTrainee />} />
          </Route>
          <Route path='profile' element={<Outlet />}>
            <Route index element={<Navigate to='/' />} />
            <Route path='settings' element={<ProfileSettings />} />
          </Route>
          <Route path='payments' element={<Outlet />}>
            <Route index element={<Navigate to='/' />} />
            <Route path='configuration' element={<PaymentsConfiguration />} />
            <Route path='product' element={<Outlet/>}>
              <Route index element={<Navigate to='/' />} />
              <Route path='create' element={<ProductPage />} />
              <Route path=':uuid/edit' element={<ProductPage />} />
            </Route>
          </Route>
        </Route>
        <Route path='/404' element={<NotFoundError />} />
        <Route path='*' element={<Navigate to='/404' />} />
      </>
    )
  }
  
  const authRoutes = () => {
    return (
      <>
        <Route path='/' element={<Outlet/>}>
          <Route index element={<Navigate to='/login' />}/>
          <Route path='login' element={<Login />}/>
          <Route path='registration' element={<Registration />}/>
          <Route path='password' element={<Outlet/>}>
            <Route path='reset' element={<ResetPassword />} />
          </Route>
        </Route>
        <Route path='*' element={<Navigate to='/login' />}/>
      </>
    )
  }

  return (
    <MainOverlay>
      <Routes>
        {
          store.getState().storage.loginState === LoginState.LOGGED 
          ? sessionRoutes()
          : authRoutes()
        }
      </Routes>
    </MainOverlay>
  )
}