import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useGetStripeBillingSession } from '../../../hooks/data/billing/useGetStripeBillingSession'

export default function SettlementCardView() {
  const theme = useTheme()
  const texts = useTranslation('profileSettings').t

  const mutation = useGetStripeBillingSession()

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent
        sx={{
          [theme.breakpoints.up('md')]: {
            padding: '2rem',
            ':last-child': {
              padding: '2rem'
            }
          },
          [theme.breakpoints.down('md')]: {
            padding: '1rem',
            ':last-child': {
              padding: '1rem'
            }
          }
        }}
      >
        <Typography variant='h4' color='#BBBBBB'>
          {texts('settlement_section_title')}
        </Typography>
        <Grid 
          marginTop='1.5rem'
          container
          flexDirection='column'
          alignItems='center'
        >
          <Grid
            sx={{
              [theme.breakpoints.up('sm')]: {
                width: '80%'
              },
              [theme.breakpoints.down('sm')]: {
                width: '100%'
              }
            }}
          >
            <Typography variant='body1'>
              {texts('settlement_description')}
            </Typography>
          </Grid>
          <Grid
            container
            marginTop='1.5rem'
            justifyContent='flex-end'
          >
            <LoadingButton
              variant='contained'
              size='medium'
              loading={mutation.isLoading}
              sx={{
                width: '15rem',
                height: '2rem !important',
                [theme.breakpoints.up('md')]: {
                  marginBottom: '0',
                  marginRight: '0'
                },
                [theme.breakpoints.down('md')]: {
                  marginBottom: '1rem',
                  marginRight: '1rem'
                },
                [theme.breakpoints.down('sm')]: {
                  marginBottom: '1rem',
                  marginRight: '0'
                }
              }}
              onClick={() => {
                mutation.mutate(window.location.href, {
                  onSuccess: (data) => {
                    window.open(data.sessionUrl, '_self')
                  }
                })
              }}
            >
              {texts('billing_button_label')}
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}