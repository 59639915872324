import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AuthApi from '../../api/auth/AuthApi'
import { TokenDto } from '../../api/auth/types'
import { logIn } from '../../redux/storageToolkit'
import store from '../../redux/store'
import { HttpError } from '../../types/common'
import { showErrorToast } from '../../utils/errors'
import { isAuthError } from '../../services/errors/utils'
import { AuthSubcodes } from '../../services/errors/consts'

const authApi = AuthApi.getInstance()

export function useFacebookLogin(onError: (error: AxiosError<HttpError>, variables: TokenDto) => void) {
  const texts = useTranslation().t
  const navigate = useNavigate()

  const mutation = useMutation(async (body: TokenDto) => {
    const { data } = await authApi.facebookLogin(body)
    return data
  }, {
    onSuccess: (data) => {
      store.dispatch(logIn(data))
      navigate('/')
    },
    onError: (error: AxiosError<HttpError>, variables: TokenDto) => {
      if (!isAuthError(error, AuthSubcodes.USER_NOT_FOUND)) {
        showErrorToast(error, texts)
      } else {
        onError(error, variables)
      }
    }
  })
  return mutation
}