import { Dialog as MuiDialog } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  open: boolean
  children: ReactNode | ReactNode[]
}

export default function FullScreenDialog(props: Props) {
  return (
    <MuiDialog 
      open={props.open}
      sx={{
        '.MuiBackdrop-root': {
          top: 0
        },
        '.MuiPaper-root': {
          boxSizing: 'border-box',
          margin: 0,
          maxWidth: 'unset',
          maxHeight: 'unset',
          width: '100%',
          borderRadius: 0,
          height: '100%'
        }
      }}
      transitionDuration={500}
    >
      {props.children}
    </MuiDialog>
  )
}