import { QueryParamConfig } from 'use-query-params'
import { SelectOption } from './common'
import { Event } from './events'

export type CalendarSearchParams = {
  from: QueryParamConfig<string | null | undefined, string>
  to: QueryParamConfig<string | null | undefined, string>
  rooms: QueryParamConfig<string | null | undefined, string | undefined>
  timesofday: QueryParamConfig<string | null | undefined, string | undefined>
  selectedDate: QueryParamConfig<string | null | undefined, string | undefined>
}

export type CalendarDate = {
  date: string
  label: string
  dayOfWeek: number
}

export enum TimeOfDay {
  MORNING = 0,    // 05:00-11:00
  MIDDAY = 1,     // 11:00-17:00
  EVENING = 2,    // 17:00-23:00
  NIGHT = 3       // 23:00-05:00
}

export type CalendarEntry = {
  hour: string
  data: {
    date: string
    data: Event[]
  }[]
}

export const timeOfDaySelectOptions: SelectOption[] = [
  {
    value: TimeOfDay.MORNING,
    i18: {
      text: 'locationCalendar:time_of_day_label',
      params: {
        value: TimeOfDay.MORNING
      }
    }
  },
  {
    value: TimeOfDay.MIDDAY,
    i18: {
      text: 'locationCalendar:time_of_day_label',
      params: {
        value: TimeOfDay.MIDDAY
      }
    }
  },
  {
    value: TimeOfDay.EVENING,
    i18: {
      text: 'locationCalendar:time_of_day_label',
      params: {
        value: TimeOfDay.EVENING
      }
    }
  },
  {
    value: TimeOfDay.NIGHT,
    i18: {
      text: 'locationCalendar:time_of_day_label',
      params: {
        value: TimeOfDay.NIGHT
      }
    }
  }
]
