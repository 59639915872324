import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { Product } from '../../../api/data/types'
import { mapListEntryDto } from '../../../mapping/product'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import { Product as ProductType, ProductList } from '../../../types/product'

const dataApi = DataApi.getInstance()

type MutationParams = {
  uuid: string
  body: Product.ProductBodyDto
}

export function useEditTrainerProduct() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const mutation = useMutation(async (params: MutationParams) => {
    const { data } = await dataApi.editTrainerProduct(params.uuid, params.body)
    return mapListEntryDto(data.data!)
  }, {
    onSuccess: (data: ProductType) => {
      const queriesData = queryClient.getQueriesData<ProductList>([queryNames.getTrainerProductList])
      for (const queryData of queriesData) {
        queryClient.setQueryData(queryData[0], (oldData?: ProductList) => {
          if (!oldData) {
            return oldData
          }
          const newData = [...oldData.products]
          const index = newData.findIndex(p => p.uuid === data.uuid)
          newData[index] = data
          return {
            totalCount: oldData.totalCount,
            products: newData
          }
        })
      }
      toast.success(texts('successes:trainer_product_updated'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}