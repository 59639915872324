export enum ValidForUnit {
  DAY = 'day',
  MONTH = 'month'
}

export type PaymentConfigurationFormData = {
  secretKey: string
  publicKey: string
}

export type ProductFormData = {
  count: number | null
  validForCount: number | null
  validForUnit: ValidForUnit
  price: string
  taxRate: string
}