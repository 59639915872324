import { Add } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import HidingFab from '../../components/customMui/HidingFab'
import MainSection from '../../components/trainees/list/MainSection'
import TopSection from '../../components/trainees/list/TopSection'
import useGetTraineeList from '../../hooks/data/trainees/useGetTraineeList'
import useGetDeviceType from '../../hooks/device/useGetDeviceType'
import { queryNames } from '../../hooks/queries'
import { RadioButtonOption } from '../../types/common'
import { TraineeListListSearchParams } from '../../types/trainees'
import ConnectionError from '../Errors/ConnectionError'
import { contentContainerId } from '../../utils/const'

export const traineesPerPage = Number.parseInt(process.env.REACT_APP_TRAINEES_PER_PAGE!)

export function isSearchParamsValid(params: DecodedValueMap<TraineeListListSearchParams>, sortOptions: RadioButtonOption[]): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
    && (
      !params.order  // no sort order defined
      || sortOptions.find(o => o.value === params.order) != null // selected sort option exists
    )
}

export default function TraineeList() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const texts = useTranslation('traineeList').t
  const { isMobile } = useGetDeviceType()

  const sortOptions: RadioButtonOption[] = texts('sort_options', {
    returnObjects: true 
  }) as RadioButtonOption[]
  
  const [searchParams, setSearchParams] = useQueryParams<TraineeListListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined),
    order: withDefault(StringParam, sortOptions[0].value)
  })

  const traineeList = useGetTraineeList({
    offset: (searchParams.page - 1) * traineesPerPage,
    limit: traineesPerPage,
    enabled: isSearchParamsValid(searchParams, sortOptions),
    order: searchParams.order,
    search: searchParams.search ? decodeURI(searchParams.search) : undefined,
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.trainees.length === 0) {
        setSearchParams({
          page: Math.ceil(data.totalCount / traineesPerPage),
        }, 'replaceIn')
      }
    }
  })

  useEffect(() => {
    document.getElementById(contentContainerId)!.scrollTo(0, 0)
  }, [searchParams.page])

  useEffect(() => {
    if (!isSearchParamsValid(searchParams, sortOptions)) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
      return
    }
    
    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (traineeList.isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          queryClient.resetQueries([queryNames.getTraineeList])
        }}
      />
    )
  }

  return (
    <>
      {
        isMobile && 
          <HidingFab onClick={() => { navigate('/trainees/invite')}}>
            <Add />
          </HidingFab>
      }
      <Grid 
        minWidth='100%'
        minHeight='100%'
        width='100%' 
        paddingX='1rem'
      >
        <TopSection />
        <MainSection 
          totalCount={traineeList.data?.totalCount ?? 0}
          isFetching={traineeList.isFetching}
          trainees={traineeList.data?.trainees ?? []}
        />
      </Grid>
    </>
  )
}