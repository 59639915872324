import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../components/customMui/Loader'
import MainSection from '../../components/trainees/details/MainSection'
import TopSection from '../../components/trainees/details/TopSection'
import useGetTraineeDetails from '../../hooks/data/trainees/useGetTraineeDetails'
import { queryNames } from '../../hooks/queries'
import { DataSubcodes } from '../../services/errors/consts'
import { isDataError } from '../../services/errors/utils'
import ConnectionError from '../Errors/ConnectionError'
import useGetStripeConfig from '../../hooks/data/payments/useGetStripeConfig'

export default function TraineeDetails() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { uuid } = useParams<{ uuid?: string }>()

  const traineeDetails = useGetTraineeDetails({
    uuid: uuid ?? '',
    onError: (error) => {
      if (isDataError(error, DataSubcodes.TRAINEE_NOT_FOUND)) {
        navigate('/404')
      }
    }
  })

  const stripeConfig = useGetStripeConfig(traineeDetails.data != null)

  if (traineeDetails.isFetching || stripeConfig.isFetching) {
    return (
      <Grid marginTop='10rem' width='100%' overflow='hidden'>
        <Loader />
      </Grid>
    )
  }

  if (traineeDetails.isError || stripeConfig.isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          queryClient.resetQueries([queryNames.getTraineeDetails, uuid])
          queryClient.resetQueries([queryNames.getStripeConfig])
        }}
      />
    )
  }

  return (
    <Grid width='100%' paddingX='1rem'>
      <TopSection fullName={traineeDetails.data!.fullName}/> 
      <MainSection 
        details={traineeDetails.data!}
        isStripeAvailable={stripeConfig.data != null}
      />
    </Grid>
  )
}