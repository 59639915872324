import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCheckoutProceed } from '../../../hooks/data/checkout/useCheckoutProceed'
import { Product } from '../../../types/product'

type Props = {
  package: Product
}

export default function PackageCardView(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('buyPackageDialog').t

  const mutation = useCheckoutProceed()

  return (
    <Card sx={{ padding: '1rem !important' }}>
      <CardContent sx={{ padding: 0, ':last-child': { padding: 0 }, height: '100%' }}>
        <Grid
          container
          item
          flexDirection='column'
          alignItems='center'
          height='100%'
        >
          <Typography
            variant='h3'
            color={theme.palette.secondary.main}
            textAlign='center'
          >
            {props.package.name}
          </Typography>
          <Grid item flexGrow={1}> </Grid>
          <Typography
            variant='h5'
            marginTop='1rem'
            textAlign='center'
          >
            {texts('price', { price: props.package.price / 100 })}
          </Typography>
          <Typography
            variant='body1'
            marginTop='.5rem'
            textAlign='center'
          >
            {
              props.package.validFor >= 30
              ? texts('valid_for_months', { count: parseInt((props.package.validFor / 30).toFixed()) })
              : texts('valid_for_days', { count: props.package.validFor })
            }
          </Typography>
          <LoadingButton
            variant='contained'
            fullWidth
            sx={{
              marginTop: '1rem'
            }}
            loading={mutation.isLoading}
            onClick={() => {
              mutation.mutate({
                uuid: props.package.uuid,
                continueUrl: window.location.href
              }, {
                onSuccess: (data) => {
                  window.open(data.url, '_self')
                }
              })
            }}
          >
            {texts('select_button_label')}
          </LoadingButton>
        </Grid>
      </CardContent>
    </Card>
  )
}
