import { CalendarSearchParams } from '../../../types/calendar'
import { DateRangeChangeType } from './ControlSection'
import { Device } from '../../../hooks/device/useGetDeviceType'
import moment from 'moment'
import { DecodedValueMap } from 'use-query-params'

type DateRangeChange = {
  params: Partial<Pick<DecodedValueMap<CalendarSearchParams>, 'from' | 'to' | 'selectedDate'>>
  resetQuery: string[] | null
}

export function getChangeDataParams(
  changeType: DateRangeChangeType, 
  params: DecodedValueMap<CalendarSearchParams> | DecodedValueMap<Omit<CalendarSearchParams, 'rooms'>>, 
  deviceType: Device,
  dateRangeLength: number
): DateRangeChange
{
  return deviceType === Device.MOBILE 
    ? getChangeMobileDataParams(changeType, params, dateRangeLength) 
    : getChangeDesktopDataParams(changeType, params, dateRangeLength)
}

function getChangeMobileDataParams(
  changeType: DateRangeChangeType, 
  params: DecodedValueMap<CalendarSearchParams> | DecodedValueMap<Omit<CalendarSearchParams, 'rooms'>>, 
  dateRangeLength: number
): DateRangeChange
{
  let from = moment(params.from, 'DD.MM.YYYY')
  let to = moment(params.to, 'DD.MM.YYYY')
  let selectedDate = moment(params.selectedDate, 'DD.MM.YYYY')
  switch (changeType) {
    case DateRangeChangeType.BACK:
      selectedDate.subtract(1, 'd')
      if (selectedDate.isBefore(from)) {
        from.subtract(dateRangeLength, 'd')
        to.subtract(dateRangeLength, 'd')
        return {
          params: {
            from: from.format('DD.MM.YYYY'),
            to: to.format('DD.MM.YYYY'),
            selectedDate: selectedDate.format('DD.MM.YYYY'),
          },
          resetQuery: [
            from.format('YYYY-MM-DD'),
            to.format('YYYY-MM-DD')
          ]
        }
      }
      return {
        params: {
          selectedDate: selectedDate.format('DD.MM.YYYY'),
        },
        resetQuery: null
      }
    case DateRangeChangeType.RESET:
      from = moment()
      to = moment().add(dateRangeLength - 1, 'day')
      selectedDate = moment()
      return {
        params: {
          from: from.format('DD.MM.YYYY'),
          to: to.format('DD.MM.YYYY'),
          selectedDate: selectedDate.format('DD.MM.YYYY')
        },
        resetQuery: [
          from.format('YYYY-MM-DD'),
          to.format('YYYY-MM-DD')
        ]
      }
    case DateRangeChangeType.FORWARD:
      selectedDate.add(1, 'd')
      if (selectedDate.isAfter(to)) {
        from.add(dateRangeLength, 'd')
        to.add(dateRangeLength, 'd')
        return {
          params: {
            from: from.format('DD.MM.YYYY'),
            to: to.format('DD.MM.YYYY'),
            selectedDate: selectedDate.format('DD.MM.YYYY'),
          },
          resetQuery: [
            from.format('YYYY-MM-DD'),
            to.format('YYYY-MM-DD')
          ]
        }
      }
      return {
        params: {
          selectedDate: selectedDate.format('DD.MM.YYYY'),
        },
        resetQuery: null
      }
  }
}

function getChangeDesktopDataParams(
  changeType: DateRangeChangeType, 
  params: DecodedValueMap<CalendarSearchParams> | DecodedValueMap<Omit<CalendarSearchParams, 'rooms'>>, 
  dateRangeLength: number
): DateRangeChange
{
  let from = moment(params.from, 'DD.MM.YYYY')
  let to = moment(params.to, 'DD.MM.YYYY')
  switch (changeType) {
    case DateRangeChangeType.BACK:
      from.subtract(dateRangeLength, 'd')
      to.subtract(dateRangeLength, 'd')
      return {
        params: {
          from: from.format('DD.MM.YYYY'),
          to: to.format('DD.MM.YYYY')
        },
        resetQuery: [
          from.format('YYYY-MM-DD'),
          to.format('YYYY-MM-DD')
        ]
      }
    case DateRangeChangeType.RESET:
      from = moment()
      to = moment().add(dateRangeLength - 1, 'day')
      return {
        params: {
          from: from.format('DD.MM.YYYY'),
          to: to.format('DD.MM.YYYY')
        },
        resetQuery: [
          from.format('YYYY-MM-DD'),
          to.format('YYYY-MM-DD')
        ]
      }
    case DateRangeChangeType.FORWARD:
      from.add(dateRangeLength, 'd')
      to.add(dateRangeLength, 'd')
      return {
        params: {
          from: from.format('DD.MM.YYYY'),
          to: to.format('DD.MM.YYYY')
        },
        resetQuery: [
          from.format('YYYY-MM-DD'),
          to.format('YYYY-MM-DD')
        ]
      }
  }
}