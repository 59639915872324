import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { DecodedValueMap, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import MainSection from '../../components/locations/list/MainSection'
import TopSection from '../../components/locations/list/TopSection'
import useGetPlaceList from '../../hooks/data/locations/useGetPlaceList'
import { queryNames } from '../../hooks/queries'
import { LocationListListSearchParams, LocationListType } from '../../types/locations'
import ConnectionError from '../Errors/ConnectionError'
import useGetCurrentLocation from '../../hooks/location/useGetCurrentLocation'
import useGetPlaceMapList from '../../hooks/data/locations/useGetPlaceMapList'
import { getUserCoordinates } from '../../services/storage/storageService'
import { contentContainerId } from '../../utils/const'

export const locationsPerPage = Number.parseInt(process.env.REACT_APP_LOCATIONS_PER_PAGE!)

export function isSearchParamsValid(params: DecodedValueMap<LocationListListSearchParams>): boolean {
  return Number.isInteger(params.page) // must be a number, not a NaN
    && params.page >= 1  // page cannot be lower than 1
    && Object.values(LocationListType).includes(params['list-type'] as any) // Check if list type is valid, as any needed because of types
}

export default function LocationList() {
  const queryClient = useQueryClient()

  const [center] = useState<google.maps.LatLngLiteral | null>(getUserCoordinates())
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null)

  const [searchParams, setSearchParams] = useQueryParams<LocationListListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined),
    'list-type': withDefault(StringParam, LocationListType.LIST)
  })

  useGetCurrentLocation(false)

  const locationList = useGetPlaceList({
    offset: (searchParams.page - 1) * locationsPerPage,
    limit: locationsPerPage,
    enabled: searchParams['list-type'] === LocationListType.LIST && isSearchParamsValid(searchParams),
    search: searchParams.search ? decodeURI(searchParams.search) : undefined,
    ...(
      center != null 
      ? {
          lat: center.lat,
          lon: center.lng
        } 
      : {}
    ),
    onSuccess: (data) => {
      if (searchParams.page > 1 && data.locations.length === 0) {
        setSearchParams({
          page: Math.ceil(data.totalCount / locationsPerPage)
        }, 'replaceIn')
      }
    }
  })

  const locationMapList = useGetPlaceMapList({
    enabled: searchParams['list-type'] === LocationListType.MAP && bounds != null,
    southwest: [
      bounds?.getNorthEast().lat() ?? 0,
      bounds?.getNorthEast().lng() ?? 0
    ],
    northeast: [
      bounds?.getSouthWest().lat() ?? 0,
      bounds?.getSouthWest().lng() ?? 0
    ],
    ...(
      center != null 
      ? {
          lat: center.lat,
          lon: center.lng
        } 
      : {}
    )
  })

  useEffect(() => {
    document.getElementById(contentContainerId)!.scrollTo(0, 0)
  }, [searchParams.page])

  useEffect(() => {
    if (!isSearchParamsValid(searchParams)) {
      setSearchParams({
        page: 1,
        search: undefined,
        'list-type': LocationListType.LIST
      }, 'replaceIn')
      return
    }
    
    if (searchParams.page === 1) {
      setSearchParams({
        page: 1
      }, 'replaceIn')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (locationList.isError || locationMapList.isError) {
    return (
      <ConnectionError 
        onRefresh={() => {
          queryClient.resetQueries([queryNames.getLocationList])
          queryClient.resetQueries([queryNames.getMapLocationList])
        }}
      />
    )
  }

  return (
    <Grid width='100%' paddingX='1rem'>
      <TopSection />
      <MainSection 
        totalCount={locationList.data?.totalCount ?? 0}
        locations={
          searchParams['list-type'] === LocationListType.LIST
          ? locationList.data?.locations ?? []
          : locationMapList.data ?? []
        }
        isFetching={
          searchParams['list-type'] === LocationListType.LIST
          ? locationList.isFetching
          : locationMapList.isFetching
        }
        onBoundsChange={(bounds) => { setBounds(bounds) }}
      />
    </Grid>
  )
}