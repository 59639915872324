import { SvgIconComponent } from '@mui/icons-material'
import { Grid, useTheme } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  icon: SvgIconComponent
  children: ReactNode | ReactNode[]
}

export default function InfoBlock(props: Props) {
  const theme = useTheme()

  return (
    <Grid
      display='grid'
      gridTemplateColumns='auto 1fr'
      columnGap='.5rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          marginTop: '1rem',
          rowGap: '.5rem'
        },
        [theme.breakpoints.down('md')]: {
          marginTop: '1rem',
          rowGap: '.25rem'
        },
        [theme.breakpoints.down('sm')]: {
          marginTop: '.75rem',
          rowGap: '.5rem'
        },
      }}
    >
      <Grid 
        gridArea='1 / 1 / 2 / 2'
        container
        alignItems='flex-start'
      >
        <props.icon
          sx={{ 
            marginTop: '-.125rem',
            [theme.breakpoints.up('sm')]: {
              fontSize: '1.5rem'
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.25rem'
            },
          }}
        />
      </Grid>
      {props.children}
    </Grid>
  )
}
