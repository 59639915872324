import { Groups } from '@mui/icons-material'
import { Grid, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { getPackageData } from '../../../redux/selectors'
import store from '../../../redux/store'
import { observeStore } from '../../../redux/utils'
import { Package } from '../../../types/account'
import { Event } from '../../../types/events'
import NoEntriesLeftDialog from '../../dialogs/NoEntriesLeftDialog'
import ConfirmReservationDialog from '../../dialogs/confirmReservation/ConfirmReservationDialog'
import BuyPackageDialog from '../../dialogs/package/BuyPackageDialog'
import NoActivePackageDialog from '../../dialogs/NoActivePackageDialog'

type Props = {
  event: Event
}

export default function CalendarEvent(props: Props) {
  const theme = useTheme()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isBuyPackageDialogOpen, setIsBuyPackageDialogOpen] = useState<boolean>(false)
  const [isNoActivePackageDialogOpen, setIsNoActivePackageDialogOpen] = useState<boolean>(false)
  const [traineeName, setTraineeName] = useState<string | null>(null)

  const [activePackage, setActivePackage] = useState<Package | null>(null)
  const [demoEntries, setDemoEntries] = useState<number>(0)
  const [isDemoAccount, setIsDemoAccount] = useState<boolean>(true)
  const [isNoEntriesLeftDialogOpen, setIsNoEntriesLeftDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    const unsubscribe = observeStore(store, getPackageData, (state) => {
      setDemoEntries(state.demoEntries)
      setIsDemoAccount(state.isDemoAccount)
      setActivePackage(state.activePackage)
    })
  
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <NoEntriesLeftDialog
        open={isNoEntriesLeftDialogOpen}
        onClose={() => { setIsNoEntriesLeftDialogOpen(false) }}
      />
      <BuyPackageDialog
        demo={isDemoAccount}
        open={isBuyPackageDialogOpen}
        onClose={() => { setIsBuyPackageDialogOpen(false) }}
      />
      <NoActivePackageDialog
        open={isNoActivePackageDialogOpen}
        traineeName={traineeName ?? ''}
        onClose={() => { setIsNoActivePackageDialogOpen(false) }}
      />
      <ConfirmReservationDialog
        open={isDialogOpen}
        event={props.event}
        onClose={() => { setIsDialogOpen(false) }}
        onNoActivePackage={(name) => {
          setTraineeName(name)
          setIsNoActivePackageDialogOpen(true)
        }}
      />
      <Grid 
        width='100%'
        container
        alignItems='center'
        gap='.25rem'
        flexWrap='nowrap'
        onClick={() => {
          if (props.event.takenSeats >= props.event.totalSeats) {
            return
          }
          if ((demoEntries === 0 || !isDemoAccount) && activePackage === null) {
            setIsBuyPackageDialogOpen(true)
          } else if (activePackage?.leftEntries === 0) {
            setIsNoEntriesLeftDialogOpen(true)
          } else {
            setIsDialogOpen(true)
          }
        }}
        sx={{
          ...(props.event.takenSeats < props.event.totalSeats ? { cursor: 'pointer' } : {}),
          border: `1px solid ${props.event.trainingRoom.color}`,
          borderRadius: '.5rem',
          padding: '.5rem',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'column'
          },
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
          },
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            alignItems: 'flex-start'
          }
        }}
      >
        <Grid
          container
          gap='.25rem'
          flexDirection='column'
          sx={{
            [theme.breakpoints.up('md')]: {
              alignItems: 'center'
            },
            [theme.breakpoints.down('md')]: {
              flexDirection: 'center'
            },
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'flex-start'
            }
          }}
        >
          <Typography
            variant='body1'
            fontWeight={700}
            textAlign='center'
            sx={{
              [theme.breakpoints.up('sm')]: {
                textAlign: 'center'
              },
              [theme.breakpoints.down('sm')]: {
                lineHeight: '1.375rem',
                textAlign: 'left'
              }
            }}
          >
            {`${moment(props.event.start, 'YYYY-MM-DD HH:mm').format('HH:mm')}-${moment(props.event.end, 'YYYY-MM-DD HH:mm').format('HH:mm')}`}
          </Typography>
          <Typography
            variant='body1'
            fontWeight={700}
            color={props.event.trainingRoom.color}
            sx={{
              wordBreak: 'break-all',
              flexGrow: 1,
              [theme.breakpoints.up('sm')]: {
                textAlign: 'center'
              },
              [theme.breakpoints.down('sm')]: {
                textAlign: 'left'
              }
            }}
          >
            {props.event.trainingRoom.name}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          gap='.5rem'
          width='fit-content'
          flexWrap='nowrap'
        >
          <Groups sx={{ marginTop: '-.125rem'}}/>
          <Typography>
            {`${props.event.takenSeats}/${props.event.totalSeats}`}
          </Typography>
        </Grid>
      </Grid>
    </>
    
  )
}