import { Success } from '../../types/common'
import { Api } from '../Api'
import { Event, Place, Reservation, Trainee, Account, Common, Session, Billing, Product, Checkout, Stripe } from './types'
import { mapTraineeListOrder } from './mapping'
import { AccountDto, PackageDto, UpdatePackageDto } from '../types'
import { AxiosResponse } from 'axios'

export default class DataApi extends Api {
  private constructor() {
    super({
      baseURL: `${process.env.REACT_APP_BASE_DATA}/api/v1/trainer`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
  private static instance: DataApi

  static getInstance(): DataApi {
    if (DataApi.instance) {
      return this.instance
    }
    return this.instance = new DataApi()
  }

  public getReservationList(options: Reservation.ListOptionsDto): Promise<AxiosResponse<Reservation.ListDto>> {
    return this.get<Reservation.ListDto>('/reservation/list', {
      params: {
        ...(options.order != null ? { order: options.order } : {}),
        ...(options.limit != null ? { limit: options.limit } : {}),
        ...(options.offset != null ? { offset: options.offset } : {}),
        ...(options.from != null ? { from: options.from } : {}),
        ...(options.to != null ? { to: options.to } : {}),
        ...(options.places != null ? { places: options.places } : {}),
        ...(options.rooms != null ? { rooms: options.rooms } : {}),
        ...(options.trainees != null ? { trainees: options.trainees } : {}),
      }
    })
  }

  public cancelReservation(uuid: string): Promise<AxiosResponse<Success<UpdatePackageDto>>> {
    return this.patch<Success<UpdatePackageDto>>(`/reservation/${uuid}/cancel`)
  }

  public searchPlaces(search: string, active?: boolean): Promise<AxiosResponse<Place.SearchDto[]>> {
    return this.get<Place.SearchDto[]>('place/search', {
      params: {
        search,
        active
      }
    })
  }

  public searchTrainees(search: string): Promise<AxiosResponse<Trainee.SearchDto[]>> {
    return this.get<Trainee.SearchDto[]>('trainee/search', {
      params: {
        search
      }
    })
  }

  public searchReservationTrainees(search: string): Promise<AxiosResponse<Trainee.SearchDto[]>> {
    return this.get<Trainee.SearchDto[]>('reservation/trainee/search', {
      params: {
        search: search
      }
    })
  }

  public getSelectedPlaces(uuids: string[], active?: boolean): Promise<AxiosResponse<Place.SearchDto[]>> {
    return this.get<Place.SearchDto[]>('place/select', {
      params: {
        uuids,
        active
      }
    })
  }

  public getSelectedTrainees(uuids: string[]): Promise<AxiosResponse<Trainee.SearchDto[]>> {
    return this.get<Trainee.SearchDto[]>('trainee/select', {
      params: {
        uuids
      }
    })
  }

  public getSelectedReservationTrainees(uuids: string[]): Promise<AxiosResponse<Trainee.SearchDto[]>> {
    return this.get<Trainee.SearchDto[]>('reservation/trainee/select', {
      params: {
        uuids: uuids
      }
    })
  }
  
  public getReservationDetails(uuid: string): Promise<AxiosResponse<Reservation.DetailsDto>> {
    return this.get<Reservation.DetailsDto>(`/reservation/${uuid}/details`)
  }

  public getEventList(options: Event.ListOptionsDto): Promise<AxiosResponse<Event.EventDto[]>> {
    return this.get<Event.EventDto[]>(`/event/list`, {
      params: {
        place: options.place,
        from: options.from,
        to: options.to,
        ...(options.rooms != null ? { rooms: options.rooms } : {}),
        ...(options.timesofday != null ? { timesofday: options.timesofday } : {})
      }
    })
  }
  
  public searchReservationPlaces(search: string): Promise<AxiosResponse<Place.SearchDto[]>> {
    return this.get<Place.SearchDto[]>('/reservation/place/search', {
      params: {
        search
      }
    })
  }

  public getSelectedReservationPlaces(uuids: string[]): Promise<AxiosResponse<Place.SearchDto[]>> {
    return this.get<Place.SearchDto[]>('/reservation/place/select', {
      params: {
        uuids
      }
    })
  }

  public getPlaceList(options: Place.ListOptionsDto): Promise<AxiosResponse<Place.ListDto>> {
    return this.get<Place.ListDto>(`place/list`, {
      params: {
        ...(options.limit != null ? { limit: options.limit } : {}),
        ...(options.offset != null ? { offset: options.offset } : {}),
        ...(options.search != null ? { search: options.search } : {}),
        ...(
          options.lat != null && options.lon != null
          ? { 
              lat: options.lat,
              lon: options.lon 
            } 
          : {}
        )
      }
    })
  }

  public getPlaceMapList(options: Place.MapListOptionsDto): Promise<AxiosResponse<Place.ListEntryDto[]>> {
    return this.get<Place.ListEntryDto[]>(`place/region/list`, {
      params: {
        southwest: options.southwest,
        northeast: options.northeast,
        ...(
          options.lat != null && options.lon != null
          ? { 
              lat: options.lat,
              lon: options.lon 
            } 
          : {}
        )
      }
    })
  }

  public createReservation(data: Reservation.CreateDto): Promise<AxiosResponse<Success<UpdatePackageDto>>> {
    return this.post<Success<UpdatePackageDto>>(`/reservation/create`, data)
  }

  public getTraineeList(options: Trainee.ListOptionsDto): Promise<AxiosResponse<Trainee.ListDto>> {
    return this.get<Trainee.ListDto>(`/trainee/list`, {
      params: {
        limit: options.limit,
        offset: options.offset,
        ...(options.search != null ? { search: options.search } : {}),
        ...(options.order != null ? { order: mapTraineeListOrder(options.order) } : {})
      }
    })
  }
  
  public getPlaceDetails(uuid: string): Promise<AxiosResponse<Place.DetailsDto>> {
    return this.get<Place.DetailsDto>(`/place/${uuid}/details`)
  }

  public getTraineeDetails(uuid: string): Promise<AxiosResponse<Trainee.DetailsDto>> {
    return this.get<Trainee.DetailsDto>(`/trainee/${uuid}/details`)
  }

  public unlinkTrainee(uuid: string): Promise<AxiosResponse<Success>> {
    return this.post<Success>(`/trainee/unlink`, { traineeUuid: uuid })
  }

  public getConnectionCode(): Promise<AxiosResponse<Account.ConnectionCodeDto>> {
    return this.get<Account.ConnectionCodeDto>(`/account/connection-code`)
  }

  public inviteTrainee(data: Common.EmailDto): Promise<AxiosResponse<Success>> {
    return this.post<Success>(`/trainee/invite`, data)
  }

  public getSessionData(): Promise<AxiosResponse<Session.SessionDataDto>> {
    return this.get<Session.SessionDataDto>('/session')
  }
  
  public getAccountData(): Promise<AxiosResponse<Success<AccountDto>>> {
    return this.get<Success<AccountDto>>('/account')
  }
  
  public editAccountData(data: Account.EditAccountDataDto): Promise<AxiosResponse<Success<AccountDto | Account.ConfirmationRequiredDto>>> {
    return this.post<Success<AccountDto | Account.ConfirmationRequiredDto>>('/account/edit', data)
  }
  
  public resendEmailVerification(email: string): Promise<AxiosResponse<Success>> {
    return this.post<Success>('/account/email/verification/resend', { email: email })
  }
  
  public changePassword(data: Account.ChangePasswordDto): Promise<AxiosResponse<Success<AccountDto>>> {
    return this.post<Success<AccountDto>>('/account/password/change', data)
  }
  
  public editTraineeNote(data: Trainee.EditNoteDto): Promise<AxiosResponse<Trainee.EditResponseDto>> {
    return this.post<Trainee.EditResponseDto>('/trainee/note/edit', data)
  }
  
  public getStripeBillingSession(returnUrl: string): Promise<AxiosResponse<Billing.SessionDto>> {
    return this.get<Billing.SessionDto>('/billing/session', {
      params: {
        returnUrl: returnUrl
      }
    })
  }

  public getProductList(options: Common.ListOptionsDto): Promise<AxiosResponse<Product.ListDto>> {
    return this.get<Product.ListDto>(`/product/kolektyw/list`, {
      params: {
        limit: options.limit,
        offset: options.offset
      }
    })
  }
  
  public checkoutProceed(data: Checkout.ProceedDto): Promise<AxiosResponse<Checkout.UrlDto>> {
    return this.post<Checkout.UrlDto>('/checkout/proceed', data)
  }

  public getStripeConfig(): Promise<AxiosResponse<Stripe.ConfigDto>> {
    return this.get<Stripe.ConfigDto>('/payment/config/stripe')
  }

  public getTrainerProductList(options: Common.ListOptionsDto): Promise<AxiosResponse<Product.ListDto>> {
    return this.get<Product.ListDto>('/product/list', {
      params: {
        limit: options.limit,
        offset: options.offset
      }
    })
  }

  public getTrainerProductDetails(uuid: string): Promise<AxiosResponse<Product.ProductDetailsDto>> {
    return this.get<Product.ProductDetailsDto>(`/product/${uuid}/details`)
  }

  public editStripeConfig(data: Stripe.ConfigDto): Promise<AxiosResponse<Success<Stripe.ConfigDto>>> {
    return this.post<Success<Stripe.ConfigDto>>('/payment/config/stripe', data)
  }

  public removeStripeConfig(): Promise<AxiosResponse<Success>> {
    return this.delete<Success>('/payment/config/stripe')
  }

  public createTrainerProduct(data: Product.ProductBodyDto): Promise<AxiosResponse<Success<Product.ProductDto>>> {
    return this.post<Success<Product.ProductDto>>('/product/create', data)
  }

  public removeTrainerProduct(uuid: string): Promise<AxiosResponse<Success>> {
    return this.delete<Success>(`/product/${uuid}`)
  }

  public editTrainerProduct(uuid: string, data: Product.ProductBodyDto): Promise<AxiosResponse<Success<Product.ProductDto>>> {
    return this.post<Success<Product.ProductDto>>(`/product/${uuid}/edit`, data)
  }

  public addPackageToTrainee(data: Trainee.AddPackageDto): Promise<AxiosResponse<Success<PackageDto>>> {
    return this.post<Success<PackageDto>>('/trainee/package/add', data)
  }
}