import { Event } from '../api/data/types'
import { Event as EventType } from '../types/events'

export function mapEventDtoList(data: Event.EventDto[]): EventType[] {
  return data.map(mapEventDto)
}

export function mapEventDto(data: Event.EventDto): EventType {
  return {
    ...data
  }
}
