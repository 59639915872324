import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import { useField } from 'formik'
import { forwardRef, useState } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

type CustomProps = {
  onChange: (event: { target: { name: string, value: string } }) => void
  onBlur?: React.FocusEventHandler<Element>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  value?: string | number
  name: string
  integerOnly?: boolean
}

const NumberFormat = forwardRef<NumericFormatProps , CustomProps>(
  function NumberFormat(props, ref) {
    const [isFocus, setIsFocus] = useState<boolean>(true)
    const { onChange, integerOnly, ...other } = props
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        fixedDecimalScale={isFocus && other.value != null && other.value !== ''}
        onBlur={(ev: any) => {
          setIsFocus(true)
          other.onBlur?.(ev)
        }}
        onFocus={(ev: any) => {
          setIsFocus(false)
          other.onFocus?.(ev)
        }}
        {
          ...integerOnly
          ? {
              decimalScale: 0
            }
          : {
              decimalSeparator: ',',
              decimalScale: 2
            }
        }
        valueIsNumericString
        allowNegative={false}
      />
    )
  },
)

type Props = {
  name: string
  integerOnly?: boolean
  noDefaultHelperText?: boolean
  shrinkLabel?: boolean
} & TextFieldProps

export default function NumericTextField(props: Props) {
  const [field, meta] = useField(props.name!)
  const { noDefaultHelperText, integerOnly, ...textFieldProps } = props

  const configTextField: TextFieldProps = {
    ...field,
    ...textFieldProps,
    fullWidth: true,
    variant: 'outlined',
    InputProps: {inputComponent: NumberFormat as any, inputProps: { integerOnly: integerOnly }},
    ...(props.shrinkLabel != null ? { InputLabelProps: { shrink: props.shrinkLabel } } : {})
  }

  if (props.name && meta && meta.touched && meta.error) {
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <MuiTextField {...configTextField} /> 
  )
}