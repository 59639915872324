import { NavigationOutlined, VisibilityOutlined } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReservationDetails } from '../../../types/reservations'
import { evalStringTemplate } from '../../../utils/text'
import { useNavigate } from 'react-router-dom'

type Props = {
  reservation: ReservationDetails
}

export default function LocationButtons(props: Props) {
  const navigate = useNavigate()
  const texts = useTranslation('reservationDetails').t

  return (
    <Grid
      container
      gap='1rem'
      width='max-content'
    >
      <Button
        variant='text'
        sx={{ color: '#66C4D6' }}
        onClick={() => {
          navigate(`/locations/${props.reservation.place.uuid}`)
        }}
      >
        <Grid
          container
          alignItems='center'
          gap='.25rem'
        >
          <VisibilityOutlined
            sx={{
              fontSize: '1.125rem'
            }}
          />
          {texts('show_location_button_label')}
        </Grid>
      </Button>
      <Button 
        variant='text'
        onClick={() => {
          window.open(
            evalStringTemplate(
              texts('navigate_url'), 
              {
                lat: props.reservation.place.coordinates[1],
                lng: props.reservation.place.coordinates[0]
              }
            ),
            '_blank'
          )
        }}
      >
        <Grid
          container
          alignItems='center'
        >
          <NavigationOutlined 
            sx={{
              fontSize: '1.125rem'
            }}
          />
          {texts('navigate_button_label')}
        </Grid>
      </Button>
    </Grid>
  )
}