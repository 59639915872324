import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { mapProductDetailsDto } from '../../../mapping/product'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

type Options = {
  enabled: boolean
  uuid: string
  onError: (error: AxiosError<HttpError>) => void
}

export default function useGetTrainerProductDetails(options: Options) {
  const texts = useTranslation().t
  const key = [
    queryNames.getTrainerProductDetails,
    options.uuid
  ]
  const query = useQuery(key, async () => {
    const { data } = await dataApi.getTrainerProductDetails(options.uuid)
    return mapProductDetailsDto(data)
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: options.enabled,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
      options.onError(error)
    }
  })

  return query
}
