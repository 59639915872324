import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import LogoIconKolektywBlackImage from '../../../../assets/images/logoIconKolektywBlack.svg'
import { useEmailCheck } from '../../../../hooks/auth/useEmailCheck'
import { mapEmailFormData } from '../../../../mapping/auth'
import { Step } from '../../../../pages/Auth/Registration'
import { OauthData, ProviderType } from '../../../../types/common'
import { EmailFormData } from '../../../../types/form/auth'
import { emailFormDataValidation } from '../../../../validations/auth'
import Link from '../../../customMui/Link'
import TextField from '../../../customMui/TextField'
import FacebookOauthButton from '../../../social/buttons/FacebookOauthButton'
import GoogleOauthButton from '../../../social/buttons/GoogleOauthButton'

type SuccessOptions = {
  accountType: ProviderType, 
  email?: string, 
  oauthData?: OauthData,
  nextStep: Step
}

type Props = {
  onSuccess: (options: SuccessOptions) => void
}

export default function RegistrationMethods(props: Props) {
  const theme = useTheme()
  const texts = useTranslation(['errors', 'registrationMethods']).t

  const emailCheckMutation = useEmailCheck()

  const handleSubmit = (data: EmailFormData) => {
    emailCheckMutation.mutate(mapEmailFormData(data), {
      onSuccess: (data, variables) => {
        if (!data.userFound) {
          props.onSuccess({
            accountType: ProviderType.PASSWORD,
            email: variables.email,
            nextStep: Step.INTRODUCE_YOURSELF
          })
        } else {
          toast.error(texts('errors:email_taken'))
        }
      }
    })
  }

  return (
    <Grid
      container
      flexDirection='column'
      alignItems='center'
      gap='1rem'
      textAlign='center'
      paddingX='2rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '4rem',
          width: '30rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '4rem',
          paddingBottom: '2rem',
          width: '30rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '2rem',
          width: '100vw'
        }
      }}
    >
      <Box
        component='img'
        src={LogoIconKolektywBlackImage}
        sx={{
          [theme.breakpoints.up('md')]: {
            height: '5.25rem'
          },
          [theme.breakpoints.down('md')]: {
            height: '4rem'
          },
          [theme.breakpoints.down('sm')]: {
            height: '4rem'
          }
        }}
      />
      <Typography variant='h1'>
        {texts('registrationMethods:title')}
        <Box 
          component='span'
          color={theme.palette.secondary.main}
        >
          {texts('registrationMethods:title_name')}
        </Box>
      </Typography>
      <Typography variant='subtitle1'>
        {texts('registrationMethods:subtitle')}
      </Typography>
      <Grid
        container
        justifyContent='center'
        gap='2rem'
      >
        <FacebookOauthButton 
          onUserNotFound={(data) => {
            props.onSuccess({
              accountType: ProviderType.FACEBOOK,
              oauthData: data,
              nextStep: Step.INTRODUCE_YOURSELF
            }) 
          }}
        />
        <GoogleOauthButton
          onUserNotFound={(data) => {
            props.onSuccess({
              accountType: ProviderType.GOOGLE,
              oauthData: data,
              nextStep: Step.INTRODUCE_YOURSELF
            }) 
          }}
        />
      </Grid>
      <Typography 
        variant='body1'
        color='#BBBBBB'
      >
        {texts('registrationMethods:or_label')}
      </Typography>
      <Grid width='100%'>
        <Formik<EmailFormData>
          initialValues={{
            email: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={emailFormDataValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <TextField
                name='email'
                label={texts('registrationMethods:email_field_label')}
                fullWidth
              />
              <LoadingButton
                type='submit'
                variant='contained'
                fullWidth
                loading={emailCheckMutation.isLoading}
                disabled={!formikProps.dirty || !formikProps.isValid}
              >
                {texts('registrationMethods:submit_button_label')}
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid>
        <Divider 
          sx={{
            marginTop: '.5em', 
            marginBottom: '1rem',
            width: '100%'
          }}
        />
        <Typography 
          variant='body1'
          marginTop='1rem'
          paddingX='1.25rem'
        >
          {texts('registrationMethods:already_have_account')}
          <Link to='/login'>
            {texts('registrationMethods:login_link_label')}
          </Link>
        </Typography>
      </Grid> 
    </Grid>
  )
}