import { defaultTraineeOptionValue } from "../utils/const"

export type LocationSearchOption = {
  value: string
  label: string
  tz: string
  active: boolean
  trainingRooms: TrainingRoomOption[]
}

export type TrainingRoomOption = {
  value: string
  label: string
  active: boolean
}

export type TraineeOption = {
  value: string
  label: string
}

export function getDefaultTraineeOption(label: string): TraineeOption {
  return {
    value: defaultTraineeOptionValue,
    label: label
  }
}