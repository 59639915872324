import { Grid, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { Questionnaire, QuestionnaireSection } from '../../types/trainees'
import BaseDialog from '../customMui/BaseDialog'

type Props = {
  questionnaire: Questionnaire
  open: boolean
  onClose: () => void
}

export default function QuestionnaireAnswersDialog(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('questionnaireAnswersDialog').t

  const questionnaire = texts('questionnaire', { returnObjects: true }) as QuestionnaireSection[]

  return (
    <BaseDialog
      open={props.open}
      title={texts('title')}
      closeButton
      onCloseButtonClick={props.onClose}
      sx={{
        '.MuiDialog-paper': {
          maxHeight: '90%',
          [theme.breakpoints.up('md')]: {
            minWidth: '37.5rem'
          },
          [theme.breakpoints.down('md')]: {
            minWidth: '32.5rem'
          },
          [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            width: '100%',
            margin: '1rem'
          },
          '::-webkit-scrollbar': {
            width: '0.4rem',
          },
          '::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            borderRadius: '0.2rem'
          }
        }
      }}
    >
      <Grid marginTop='1.5rem'>
        {
          questionnaire.map((section, sectionIdx) => (
            <Grid
              key={`questionnaire-section-${sectionIdx}`}
              sx={{
                marginTop: sectionIdx === 0 ? 0 : '1.5rem'
              }}
            >
              <Typography
                variant='h5'
                component='p'
                color='#BBBBBB'
              >
                {section.title}
              </Typography>
              <Grid>
                {
                  section.questions.map((question, questionIdx) => (
                    <Grid
                      key={`questionnaire-answer-${sectionIdx}-${questionIdx}`}
                      marginTop='1rem'
                    >
                      <Typography
                        variant='body1'
                        fontWeight={700}
                      >
                        {question.question}
                      </Typography>
                      <Typography 
                        variant='body1'
                        sx={{
                          marginTop: '.5rem'
                        }}
                      >
                        <Trans
                          defaults={question.icuAnswer}
                          values={{
                            answer: props.questionnaire.answers[sectionIdx][questionIdx]
                          }}
                        />
                      </Typography>
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          ))
        }
      </Grid>
    </BaseDialog>
  )
}