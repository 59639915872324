import LoadingButton from '@mui/lab/LoadingButton'
import { Divider, Grid, Typography, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { PinFormData } from '../../../../types/form/auth'
import { pinFormDataValidation } from '../../../../validations/auth'
import FormikPincode from '../../../customReact/FormikPincode'
import { useVerifyEmail } from '../../../../hooks/auth/useVerifyEmail'
import { mapPinFormData } from '../../../../mapping/auth'
import { useResendVerificationEmail } from '../../../../hooks/auth/useResendVerificationEmail'

type Props = {
  email: string
}

export default function ActivateAccount(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('activateAccount').t

  const verifyEmailMutation = useVerifyEmail()
  const resendEmailMutation = useResendVerificationEmail()

  const handleSubmit = (data: PinFormData) => {
    verifyEmailMutation.mutate(mapPinFormData(data, props.email))
  }

  return (
    <Grid
      container
      flexDirection='column'
      alignItems='center'
      gap='1rem'
      textAlign='center'
      paddingX='2rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '10rem',
          width: '30rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '4rem',
          paddingBottom: '2rem',
          width: '30rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '2rem',
          width: '100vw'
        }
      }}
    >
      <Typography variant='h1'>
        {texts('title')}
      </Typography>
      <Grid 
        width='100%' 
        marginTop='1rem'
      >
        <Formik<PinFormData>
          initialValues={{
            pin: ''
          }}
          onSubmit={handleSubmit}
          validateOnBlur
          validationSchema={pinFormDataValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <Grid marginBottom='1.5rem'>
                <FormikPincode name='pin'/>
              </Grid>
              <LoadingButton
                type='submit'
                variant='contained'
                fullWidth
                loading={verifyEmailMutation.isLoading}
                disabled={!formikProps.dirty || !formikProps.isValid}
              >
                {texts('submit_button_label')}
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid>
        <Divider
          sx={{
            marginTop: '.5em', 
            marginBottom: '1rem',
            width: '100%'
          }}
        />
        <Typography 
          variant='body1'
          marginTop='1rem'
          paddingX='1.25rem'
        >
          {texts('message_not_arrived')}
          <Typography 
            variant='textButton'
            sx={{
              cursor: 'pointer'
            }}
            onClick={() => {
              if (!resendEmailMutation.isLoading) {
                resendEmailMutation.mutate(props.email)
              }
            }}
          >
            {texts('send_again_label')}
          </Typography>
        </Typography>
      </Grid> 
    </Grid>
  )
}