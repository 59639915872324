import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, Typography, useTheme } from '@mui/material'
import { ReactElement } from 'react'
import BaseDialog from './BaseDialog'

type Props = {
  open: boolean
  title: string | ReactElement
  description: string | ReactElement
  warning?: string | ReactElement
  closeButtonLabel?: string
  confirmButtonLabel: string
  loading?: boolean
  onCloseButtonClick?: () => void
  onConfirmButtonClick?: () => void
}

export default function Dialog(props: Props) {
  const theme = useTheme()

  return (
    <BaseDialog
      open={props.open}
      title={props.title}
      onCloseButtonClick={props.onCloseButtonClick}
    >
      <DialogContent>
        <Typography variant='body1'>
          {props.description}
        </Typography>
        {
          props.warning
          && <Typography variant='body1' color={theme.palette.error.main} sx={{ marginTop: '1rem' }}>
              {props.warning}
             </Typography>
        }
      </DialogContent>
      <DialogActions>
        {
          props.closeButtonLabel &&
            <Button
              variant='outlined'
              size='medium'
              onClick={props.onCloseButtonClick}
              sx={{
                width: '10rem',
                height: '2rem'
              }}
            >
              {props.closeButtonLabel}
            </Button>
        }
        <LoadingButton
          variant='contained'
          size='medium'
          loading={props.loading}
          onClick={props.onConfirmButtonClick}
          sx={{
            width: '10rem',
            height: '2rem'
          }}
        >
          {props.confirmButtonLabel}
        </LoadingButton>
      </DialogActions>
    </BaseDialog>
  )
}