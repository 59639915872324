import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { mapConfigDto } from '../../../mapping/payments'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'

const dataApi = DataApi.getInstance()

export default function useGetStripeConfig(enabled?: boolean) {
  const texts = useTranslation().t
  const query = useQuery([queryNames.getStripeConfig], async () => {
    const { data } = await dataApi.getStripeConfig()
    return data ? mapConfigDto(data) : null
  }, {
    staleTime: Infinity,
    refetchOnMount: 'always',
    enabled: enabled,
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })

  return query
}
