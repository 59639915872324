import { Grid, Pagination } from '@mui/material'
import { ReactNode, useMemo } from 'react'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import { isSearchParamsValid, locationsPerPage } from '../../../pages/Location/LocationList'
import { Location, LocationListListSearchParams, LocationListType } from '../../../types/locations'
import Loader from '../../customMui/Loader'
import ListView from './ListView'
import MapView from './MapView'

type Props = {
  totalCount: number
  locations: Location[]
  isFetching: boolean
  onBoundsChange: (bounds: google.maps.LatLngBounds | null) => void
}

function Container(props: { children: ReactNode | ReactNode[] }) {
  return (
    <Grid 
      container
      flexWrap='nowrap'
      flexDirection='column'
      width='100%'
      paddingBottom='1.5rem'
    >
      {props.children}
    </Grid>
  )
}

export default function MainSection(props: Props) {
  const { isMobile } = useGetDeviceType()
  
  const [searchParams, setSearchParams] = useQueryParams<LocationListListSearchParams>({ 
    page: withDefault(NumberParam, 1),
    search: withDefault(StringParam, undefined),
    'list-type': withDefault(StringParam, LocationListType.LIST)
  })

  // Prepared commented variant for logic
  const maxPage = useMemo(() => {
    return Math.ceil((props.totalCount ?? 0) / locationsPerPage)
  }, [props.totalCount])

  const onPageChange = (_: React.ChangeEvent<any>, value: number) => {
    setSearchParams({ page: value }, 'push')
  }

  if (searchParams['list-type'] === LocationListType.LIST && props.isFetching) {
    return (
      <Container>
        <Grid marginTop='10rem' width='100%' overflow='hidden'>
          <Loader />
        </Grid>
      </Container>
    )
  }

  const isPageNotFound = searchParams['list-type'] === LocationListType.LIST && searchParams.page > 1 && props.locations.length === 0
  if (!isSearchParamsValid(searchParams) || isPageNotFound) {
    return <></>
  }

  return (
    <Container>
      {
        searchParams['list-type'] === LocationListType.LIST &&
          <>
            <ListView locations={props.locations}/>
            {
              maxPage > 1 &&
                <Pagination 
                  count={maxPage}
                  page={searchParams.page}
                  color='secondary'
                  onChange={onPageChange}
                  sx={{
                    alignSelf: 'center',
                    marginTop: '1rem'
                  }}
                  siblingCount={isMobile ? 0 : 2}
                />
            }
          </>
      }
      {
        searchParams['list-type'] === LocationListType.MAP &&
          <Grid paddingTop='1rem'>
            <MapView 
              onBoundsChange={props.onBoundsChange}
              locations={props.locations}
              isFetching={props.isFetching}
            />
          </Grid>
      }
    </Container>
  )
}