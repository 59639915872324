import LoadingButton from '@mui/lab/LoadingButton'
import { Grid, Typography, useTheme } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { PasswordFormData } from '../../../../types/form/auth'
import { passwordFormDataValidation } from '../../../../validations/auth'
import PasswordField from '../../../customMui/PasswordField'
import { useFinishPwdReset } from '../../../../hooks/auth/useFinishPwdReset'
import { mapPasswordFormData } from '../../../../mapping/auth'
import { useRef } from 'react'

type Props = {
  token: string
}

export default function FinishPasswordReset(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('finishPasswordReset').t

  const formRef = useRef<FormikProps<PasswordFormData>>(null)

  const mutation = useFinishPwdReset()

  const handleSubmit = (data: PasswordFormData) => {
    mutation.mutate({
      data: mapPasswordFormData(data),
      token: props.token
    }, {
      onError: () => {
        formRef.current?.resetForm()
      }
    })
  }

  return (
    <Grid
      container
      flexDirection='column'
      alignItems='center'
      gap='1rem'
      textAlign='center'
      paddingX='2rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '10rem',
          width: '30rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '4rem',
          paddingBottom: '2rem',
          width: '30rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '2rem',
          width: '100vw'
        }
      }}
    >
    <Typography 
      variant='h1' 
      marginBottom='1rem'
    >
        {texts('title')}
      </Typography>
      <Grid width='100%'>
        <Formik<PasswordFormData>
          initialValues={{
            password: ''
          }}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validationSchema={passwordFormDataValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <PasswordField
                name='password'
                label={texts('password_field_label')}
              />
              <LoadingButton
                type='submit'
                variant='contained'
                fullWidth
                loading={mutation.isLoading}
                disabled={!formikProps.dirty || !formikProps.isValid}
              >
                {texts('submit_button_label')}
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}