import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import { Trainee } from '../../../api/data/types'
import { TraineeDetails } from '../../../types/trainees'
import { mapPackageDto } from '../../../mapping/common'

const dataApi = DataApi.getInstance()

export function useAddPackageToTrainee() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const mutation = useMutation(async (body: Trainee.AddPackageDto) => {
    const { data } = await dataApi.addPackageToTrainee(body)
    return mapPackageDto(data.data!)
  }, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData([queryNames.getTraineeDetails, variables.userUuid], (oldData?: TraineeDetails) => {
        if (!oldData) {
          return oldData
        }
        return {
          ...oldData,
          package: data
        }
      })
      toast.success(texts('successes:package_added'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}