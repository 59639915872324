import { Clear } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import useSearchReservationLocations from '../../../../hooks/data/locations/useSearchReservationLocations'
import useGetDeviceType from '../../../../hooks/device/useGetDeviceType'
import { mapSearchDataList as mapLocationSearchDataList } from '../../../../mapping/locations'
import { mapSearchDataList as mapTraineeSearchDataList } from '../../../../mapping/trainees'
import { LocationSearchOption, TraineeOption, TrainingRoomOption } from '../../../../types/filters'
import { ReservationFilterFormData } from '../../../../types/form/filter'
import FormikDateRangePicker from '../../../customMui/FormikDateRangePicker'
import FormikExternalMultiAutocomplete from '../../../customMui/FormikExternalMultiAutocomplete'
import FormikMultiAutocomplete from '../../../customMui/FormikMultiAutocomplete'
import useSearchReservationTrainees from '../../../../hooks/data/trainees/useSearchReservationTrainees'
import { reservationFilterFormDataValidation } from '../../../../validations/reservation'
import { queryNames } from '../../../../hooks/queries'
import { useQueryClient } from '@tanstack/react-query'

type Props = {
  initValues: ReservationFilterFormData
  onSubmit: (data: ReservationFilterFormData) => void
}

export default function FiltersForm(props: Props) {
  const texts = useTranslation('reservationList').t
  const queryClient = useQueryClient()

  const { isDesktop } = useGetDeviceType()
  
  const [locationSearch, setLocationSearch] = useState<string>('')
  const [traineeSearch, setTraineeSearch] = useState<string>('')

  const locationList = useSearchReservationLocations(locationSearch)
  const traineeList = useSearchReservationTrainees(traineeSearch)

  const debounceLocationSearch = useDebouncedCallback((value) => {
    setLocationSearch(value)
    queryClient.resetQueries([queryNames.searchReservationLocations, value])
  }, 300)

  const debounceTraineeSearch = useDebouncedCallback((value) => {
    setTraineeSearch(value)
    queryClient.resetQueries([queryNames.searchReservationTrainees, value])
  }, 300)

  return (
    <Formik<ReservationFilterFormData>
      initialValues={props.initValues}
      enableReinitialize
      onSubmit={props.onSubmit}
      validateOnMount
      validationSchema={reservationFilterFormDataValidation(texts)}
    >
      {(formikProps) => (
        <Form>
          <Grid
            container
            alignItems='center'
            marginBottom='2rem'
            justifyContent='space-between'
          >
            <Typography variant='h4'>
              {texts('general_filters_title')}
            </Typography>
            {
              !isDesktop &&
                <Button
                  variant='text'
                  onClick={() => {
                    formikProps.setFieldValue('locations', [])
                    formikProps.setFieldValue('rooms', [])
                    formikProps.setFieldValue('trainees', [])
                    formikProps.setFieldValue('dateRange', [
                      null,
                      null
                    ])
                  }}
                >
                  <Grid
                    container
                    alignItems='center'
                    gap='.5rem'
                  >
                    <Clear sx={{ marginTop: '-.125rem' }}/>
                    {texts('filter_clear_button_label')}
                  </Grid>
                </Button>
            }
          </Grid>
          <Grid>
            <FormikExternalMultiAutocomplete<LocationSearchOption>
              name='locations'
              label={texts('filter_locations_field_label')}
              placeholder={texts('filter_all_placeholder')}
              loading={locationList.isFetching}
              shrink
              limitTags={3}
              options={mapLocationSearchDataList(locationList.data ?? [])}
              getOptionLabel={(option) => texts('inactive_option_label', { active: option.active ? 1 : 0, label: option.label })}
              onInputChange={(event, value) => {
                debounceLocationSearch(event.type === 'change' ? value : '')
              }}
              onChange={(values) => {
                formikProps.setFieldValue('rooms', formikProps.values.rooms.filter(r => values.findIndex(v => v.trainingRooms.findIndex(tr => tr.value === r.value) !== -1) !== -1))
              }} 
            />
            <FormikMultiAutocomplete<TrainingRoomOption>
              name='rooms'
              label={texts('filter_rooms_field_label')}
              placeholder={texts('filter_all_placeholder')}
              shrink
              limitTags={3}
              getOptionLabel={(option) => texts('inactive_option_label', { active: option.active ? 1 : 0, label: option.label })}
              disabled={formikProps.values.locations.length === 0}
              options={formikProps.values.locations.map(e => e.trainingRooms).flat()}
            />
            <FormikExternalMultiAutocomplete<TraineeOption>
              name='trainees'
              label={texts('filter_trainees_field_label')}
              placeholder={texts('filter_all_placeholder')}
              loading={traineeList.isFetching}
              shrink
              options={mapTraineeSearchDataList(traineeList.data ?? [])}
              onInputChange={(event, value) => {
                debounceTraineeSearch(event.type === 'change' ? value : '')
              }}
            />
          </Grid>
          {
            !isDesktop &&
              <>
                <Grid>
                  <Typography 
                    variant='body1'
                    fontWeight={700}
                    marginBottom='1rem'
                  >
                    {texts('general_filters_date_range_section_label')}
                  </Typography>
                </Grid>
                <FormikDateRangePicker
                  name='dateRange'
                  labels={{
                    from: texts('date_range_from'),
                    to: texts('date_range_to')
                  }}
                />
              </>
          }
          {
            isDesktop &&
              <Grid marginBottom='1rem' marginTop='-1rem'>
                <Button
                  variant='text'
                  onClick={() => {
                    formikProps.setFieldValue('locations', [])
                    formikProps.setFieldValue('rooms', [])
                    formikProps.setFieldValue('trainees', [])
                    formikProps.setFieldValue('dateRange', [
                      moment().startOf('month').format('DD.MM.YYYY'),
                      moment().endOf('month').format('DD.MM.YYYY')
                    ])
                  }}
                >
                  <Grid
                    container
                    alignItems='center'
                    gap='.5rem'
                  >
                    <Clear sx={{ marginTop: '-.125rem' }}/>
                    {texts('filter_clear_button_label')}
                  </Grid>
                </Button>
              </Grid>
          }
          <Grid
            container
            justifyContent='flex-end'
          >
            <Button
              type='submit'
              variant='contained'
              disabled={!isDesktop && formikProps.isValid}
              sx={{ width: '10rem' }}
            >
              {texts('filter_submit_button_label')}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

