import LoadingButton from '@mui/lab/LoadingButton'
import { Divider, Grid, Typography, useTheme } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { PinFormData } from '../../../../types/form/auth'
import { pinFormDataValidation } from '../../../../validations/auth'
import FormikPincode from '../../../customReact/FormikPincode'
import { useCheckPwdResetCode } from '../../../../hooks/auth/useCheckPwdResetCode'
import { mapPinFormData } from '../../../../mapping/auth'
import { useRef } from 'react'
import { useStartPwdReset } from '../../../../hooks/auth/useStartPwdReset'

type Props = {
  onSuccess: (token: string) => void
  email: string
}

export default function VerifyCode(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('verifyCode').t

  const formRef = useRef<FormikProps<PinFormData>>(null)
  const ref = useRef<HTMLInputElement[]>(null)

  const mutation = useCheckPwdResetCode()
  const resendMutation = useStartPwdReset()

  const handleSubmit = (data: PinFormData) => {
    mutation.mutate(mapPinFormData(data, props.email), {
      onSuccess: (data) => {
        props.onSuccess(data.token)
      },
      onError: () => {
        formRef.current?.resetForm()
        ref.current?.forEach(input => {
          input.value = ''
        })
        ref.current?.[0].focus()
      }
    })
  }

  return (
    <Grid
      container
      flexDirection='column'
      alignItems='center'
      gap='1rem'
      textAlign='center'
      paddingX='2rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '10rem',
          width: '30rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '4rem',
          paddingBottom: '2rem',
          width: '30rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '2rem',
          width: '100vw'
        }
      }}
    >
      <Typography variant='h1'>
        {texts('title')}
      </Typography>
      <Grid 
        width='100%' 
        marginTop='1rem'
      >
        <Formik<PinFormData>
          initialValues={{
            pin: ''
          }}
          onSubmit={handleSubmit}
          innerRef={formRef}
          validateOnBlur
          validationSchema={pinFormDataValidation(texts)}
        >
          {(formikProps) => (
            <Form>
              <Grid marginBottom={'1.5rem'}>
                <FormikPincode 
                  name='pin'
                  ref={ref}
                />
              </Grid>
              <LoadingButton
                type='submit'
                variant='contained'
                fullWidth
                loading={mutation.isLoading}
                disabled={!formikProps.dirty || !formikProps.isValid}
              >
                {texts('submit_button_label')}
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid>
        <Divider
          sx={{
            marginTop: '.5em', 
            marginBottom: '1rem',
            width: '100%'
          }}
        />
        <Typography 
          variant='body1'
          marginTop='1rem'
          paddingX='1.25rem'
        >
          {texts('message_not_arrived')}
          <Typography 
            variant='textButton'
            sx={{
              cursor: 'pointer'
            }}
            onClick={() => {
              if (!resendMutation.isLoading) {
                resendMutation.mutate(props.email)
              }
            }}
          >
            {texts('send_again_label')}
          </Typography>
        </Typography>
      </Grid> 
    </Grid>
  )
}