import { NavigationOutlined } from '@mui/icons-material'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'
import { LocationDetails } from '../../../types/locations'
import { evalStringTemplate } from '../../../utils/text'
import InfoButton from './InfoButton'
import Image from './Image'
import InfoCardView from './InfoCardView'
import RoomListSection from './RoomListSection'

type Props = {
  location: LocationDetails
}

export default function MainSection(props: Props) {
  const theme = useTheme()
  const { isDesktop } = useGetDeviceType()
  const texts = useTranslation('locationDetails').t

  return (
    <Grid
      display='grid'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingBlock: '2rem',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: 'repeat(2, auto)',
          columnGap: '1.5rem',
          rowGap: '2rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '1rem',
          paddingBottom: '1.5rem',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gridTemplateRows: 'repeat(2, auto)',
          columnGap: '1.5rem',
          rowGap: '2rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: '1rem',
          paddingBottom: '1.5rem',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gridTemplateRows: 'repeat(2, auto)',
          columnGap: '1.5rem',
          rowGap: '2rem'
        }
      }}
    >
      <Grid gridArea='1 / 1 / 2 / 3'>
        {
          !isDesktop &&
            <Grid
              container
              justifyContent='flex-end'
              gap='1rem'
              sx={{
                marginBottom: '1rem'
              }}
            >
              <Button 
                variant='text'
                onClick={() => {
                  window.open(
                    evalStringTemplate(
                      texts('navigate_url'), 
                      {
                        lat: props.location.coordinates[1],
                        lng: props.location.coordinates[0]
                      }
                    ),
                    '_blank'
                  )
                }}
              >
                <Grid
                  container
                  alignItems='center'
                >
                  <NavigationOutlined 
                    sx={{
                      fontSize: '1.125rem'
                    }}
                  />
                  {texts('navigate_button_label')}
                </Grid>
              </Button>
              <InfoButton location={props.location} />
            </Grid>
        }
        <Image src={props.location.photo?.original} />
        {
          !isDesktop &&
            <Grid
              container
              flexDirection='column'
              marginTop='1rem'
              gap='.5rem'
            >
              <Typography variant='h2'>
                {props.location.name}
              </Typography>
              <Typography variant='body1'>
                {texts(
                  'address_label',
                  {
                    streetAddress: props.location.address.streetAddress,
                    postalCode: props.location.address.postalCode,
                    town: props.location.address.town
                  }
                )}
              </Typography>
            </Grid>
        }
      </Grid>
      <Grid gridArea='2 / 1 / 3 / 3'>
        {
          props.location.trainingRooms.length > 0 &&
            <RoomListSection 
              locationUuid={props.location.uuid}
              rooms={props.location.trainingRooms}
            />
        }
      </Grid>
      {
        isDesktop &&
          <Grid gridArea='1 / 3 / 3 / 4'>
            <InfoCardView location={props.location}/>
          </Grid>
      }
    </Grid>
  )
}