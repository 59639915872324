import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Account } from '../../../api/data/types'
import { useEditAccountData } from '../../../hooks/data/account/useEditAccountData'
import { mapAccountToAccountForm, mapProfileFormData } from '../../../mapping/account'
import { ProfileFormData } from '../../../types/form/account'
import { Account as StorageAccount } from '../../../types/storage'
import { profileFormDataValidation } from '../../../validations/profile'
import TextField from '../../customMui/TextField'
import ConfirmEmailChangeDialog from '../../dialogs/ConfirmEmailChangeDialog'

type Props = {
  account: StorageAccount
}

export default function ProfileDataCardView(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('profileSettings').t

  const mutation = useEditAccountData()

  const [dataToVerify, setDataToVerify] = useState<ProfileFormData | null>(null)

  const handleSubmit = (data: ProfileFormData) => {
    mutation.mutate(mapProfileFormData(data), {
      onSuccess: (res) => {
        if ((res.data as Account.ConfirmationRequiredDto).confirmationRequired) {
          setDataToVerify(data)
        }
      }
    })
  }

  return (
    <>
      <ConfirmEmailChangeDialog
        open={Boolean(dataToVerify)}
        account={dataToVerify}
        onClose={() => { 
          setDataToVerify(null)
        }}
      />
      <Card sx={{ width: '100%' }}>
        <CardContent
          sx={{
            [theme.breakpoints.up('md')]: {
              padding: '2rem',
              ':last-child': {
                padding: '2rem'
              }
            },
            [theme.breakpoints.down('md')]: {
              padding: '1rem',
              ':last-child': {
                padding: '1rem'
              }
            }
          }}
        >
          <Typography variant='h4' color='#BBBBBB'>
            {texts('profile_data_section_title')}
          </Typography>
          <Grid marginTop='1.5rem'>
            <Formik<ProfileFormData>
              initialValues={mapAccountToAccountForm(props.account)}
              enableReinitialize
              onSubmit={handleSubmit}
              validationSchema={profileFormDataValidation(texts)}
            >
              {(formikProps) => (
                <Form>
                  <Grid
                    container
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Grid
                      sx={{
                        [theme.breakpoints.up('sm')]: {
                          width: '80%'
                        },
                        [theme.breakpoints.down('sm')]: {
                          width: '100%'
                        }
                      }}
                    >
                      <Grid 
                        container
                        columnGap='1rem'
                        sx={{
                          [theme.breakpoints.up('sm')]: {
                            flexWrap: 'nowrap'
                          },
                          [theme.breakpoints.down('sm')]: {
                            flexWrap: 'wrap'
                          }
                        }}
                      >
                        <Grid item sm={6} xs={12}>
                          <TextField
                            name='firstName'
                            fullWidth
                            label={texts('first_name_field_label')}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextField
                            name='lastName'
                            fullWidth
                            label={texts('last_name_field_label')}
                          />
                        </Grid>
                      </Grid>
                      <TextField
                        name='nick'
                        fullWidth
                        label={texts('nick_field_label')}
                      />
                      <TextField
                        name='email'
                        fullWidth
                        label={texts('email_field_label')}
                      />
                      <TextField
                        name='phone'
                        fullWidth
                        label={texts('phone_field_label')}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    marginTop='1rem'
                    justifyContent='flex-end'
                  >
                    <LoadingButton
                      type='submit'
                      variant='contained'
                      size='medium'
                      loading={mutation.isLoading}
                      disabled={!formikProps.dirty || !formikProps.isValid}
                      sx={{
                        width: '15rem',
                        height: '2rem !important',
                        [theme.breakpoints.up('md')]: {
                          marginBottom: '0',
                          marginRight: '0'
                        },
                        [theme.breakpoints.down('md')]: {
                          marginBottom: '1rem',
                          marginRight: '1rem'
                        },
                        [theme.breakpoints.down('sm')]: {
                          marginBottom: '1rem',
                          marginRight: '0'
                        }
                      }}
                    >
                      {texts('submit_button_label')}
                    </LoadingButton>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}