import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/data/DataApi'
import { HttpError } from '../../../types/common'
import { showErrorToast } from '../../../utils/errors'
import { queryNames } from '../../queries'
import { Trainee } from '../../../api/data/types'
import { TraineeDetails } from '../../../types/trainees'

const dataApi = DataApi.getInstance()

export function useEditTraineeNote() {
  const texts = useTranslation().t
  const queryClient = useQueryClient()

  const mutation = useMutation(async (body: Trainee.EditNoteDto) => {
    const { data } = await dataApi.editTraineeNote(body)
    return data
  }, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData([queryNames.getTraineeDetails, variables.traineeUuid], (oldData?: TraineeDetails) => {
        if (!oldData) {
          return oldData
        }
        return {
          ...oldData,
          note: data.note
        }
      })
      toast.success(texts('successes:trainee_note_updated'))
    },
    onError: (error: AxiosError<HttpError>) => {
      showErrorToast(error, texts)
    }
  })
  return mutation
}