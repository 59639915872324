import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function DemoAccountSection() {
  const theme = useTheme()
  const texts = useTranslation('summaryPanel').t
  const navigate = useNavigate()

  return (
    <Grid
      container
      flexWrap='nowrap'
      gap='1rem'
      sx={{
        backgroundColor: 'rgba(56, 176, 0, 0.3)',
        borderRadius: '.25rem',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          padding: '1.5rem 2rem'
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'flex-end',
          padding: '1rem'
        }
      }}
    >
      <Grid flexGrow={1}>
        <Typography
          variant='body1'
          color='#38B000'
        >
          {texts('demo_access_description')}
        </Typography>
      </Grid>
      <Grid
        sx={{
          fontSize: 0 // Fix for weird space added above button (similar case with white space under img that sometimes shows)
        }}
      >
        <Button
          variant='text'
          sx={{
            color: '#38B000'
          }}
          onClick={() => { navigate('/locations') }}
        >
          <Typography
            variant='body1'
            color='#38B000'
            fontWeight={700}
            sx={{
              wordBreak: 'keep-all',
            }}
          >
            {texts('demo_access_button_label')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  )
}