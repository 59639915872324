import { AuthDto } from '../api/auth/types'
import { AccountDto } from '../api/types'
import { Account as StorageAccount } from '../types/storage'

export function mapAuthDto(data: AuthDto): StorageAccount {
  return {
    email: data.user.email,
    uuid: data.user.uuid,
    firstName: data.user.firstName,
    lastName: data.user.lastName,
    nick: data.user.nick,
    newsletter: data.user.newsletter,
    oAuthProvider: data.user.oAuthProvider,
    contact: data.user.contact,
    demoEntries: data.user.demoEntries,
    demo: data.user.demo,
    package: data.user.package
  }
}

export function mapAccountDto(data: AccountDto): StorageAccount {
  return {
    email: data.email,
    uuid: data.uuid,
    firstName: data.firstName,
    lastName: data.lastName,
    nick: data.nick,
    newsletter: data.newsletter,
    oAuthProvider: data.oAuthProvider,
    contact: data.contact,    
    demoEntries: data.demoEntries,
    demo: data.demo,
    package: data.package
  }
}