import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useGetDeviceType from '../../../hooks/device/useGetDeviceType'

export default function TopSection() {
  const theme = useTheme()
  const navigate = useNavigate()
  const texts = useTranslation('reservationList').t
  const { isMobile } = useGetDeviceType()

  return (
    <Grid
      container
      justifyContent='space-between'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingTop: '2rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: '1.5rem'
        }
      }}
    >
      <Typography variant='h2'>
        {texts('title')}
      </Typography>
      {
        !isMobile &&
          <Button
            variant='contained'
            onClick={() => { navigate('/locations')}}
            sx={{
              width: '17.5rem'
            }}
          >
            {texts('new_reservation_button_label')}
          </Button>
      }
    </Grid>
  )
}