import { Button, useTheme } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  selected?: boolean
  onClick: () => void
  children: ReactNode | ReactNode[]
}

export default function SelectButton(props: Props) {
  const theme = useTheme()

  return (
    <Button
      variant='outlined'
      onClick={props.onClick}
      sx={{
        borderRadius: '.25rem',
        color: '#5C0F8B',
        borderColor: '#5C0F8B',
        ...(props.selected ? { backgroundColor: 'rgba(92 15 139 / 0.08)' } : {}),
        [theme.breakpoints.up('md')]: {
          height: '2.625rem'
        },
        [theme.breakpoints.down('md')]: {
          height: '2.625rem'
        },
        [theme.breakpoints.down('sm')]: {
          height: '2.25rem'
        }
      }}
    >
      {props.children}
    </Button>
  )
}