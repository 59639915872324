import { ListItem, ListItemText, Typography } from '@mui/material'

type Props = { 
  label: string | null
  expanded: boolean 
}

export default function DrawerListSection(props: Props) {
  return (
    <ListItem disablePadding sx={{ display: props.expanded ? 'block' : 'none' }}>
      <ListItemText
        sx={{
          opacity: props.expanded ? 1 : 0,
          margin: '2rem 0 .5rem 1.5rem'
        }}
      >
        {
          props.label
          &&  <Typography variant='menuLabel'>
                {props.label}
              </Typography>
        }
      </ListItemText>
    </ListItem>
  )
}