import { Common } from '../api/data/types'
import { PackageDto } from '../api/types'
import { Package } from '../types/account'
import { Address, Contact } from '../types/common'

export function mapDistanceValue(meters: number): string {
  if (meters > 1000) {
    return Intl.NumberFormat(
      'pl-PL', 
      { 
        style: 'unit',
        unit: 'kilometer',
        unitDisplay: 'short',
        maximumFractionDigits: 1
      }
    ).format(meters / 1000)
  }
  return Intl.NumberFormat(
    'pl-PL', 
    { 
      style: 'unit',
      unit: 'meter',
      unitDisplay: 'short',
      maximumFractionDigits: 0
    }
  ).format(meters)
}

export function mapAddressDto(data: Common.AddressDto): Address {
  return {
    ...data
  }
}

export function mapContactDto(data: Common.ContactDto): Contact {
  return {
    ...data
  }
}

export function mapPackageDto(data: PackageDto): Package {
  return {
    ...data
  }
}

export function mapFullName(firstName: string, lastName: string | null): string {
  return `${firstName}${lastName ? ` ${lastName}` : ''}`
}