import { Grid, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ChangePwdCardView from '../components/profile/settings/ChangePwdCardView'
import ProfileDataCardView from '../components/profile/settings/ProfileDataCardView'
import { getUserData } from '../services/storage/storageService'
import PackageCardView from '../components/profile/settings/PackageCardView'
import SettlementCardView from '../components/profile/settings/SettlementCardView'
import { ProviderType } from '../api/types'

export default function ProfileSettings() {
  const theme = useTheme()
  const texts = useTranslation('profileSettings').t
  const user = getUserData()!

  return (
    <Grid
      width='100%'
      paddingX='1rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          paddingBlock: '2rem'
        },
        [theme.breakpoints.down('md')]: {
          paddingBlock: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
          paddingBlock: '1.5rem'
        }
      }}
    >
      <Typography variant='h2'>
        {texts('title')}
      </Typography>
      <Grid
        container
        flexDirection='column'
        alignItems='center'
      >
        <Grid
          container
          marginTop='1.5rem'
          flexDirection='column'
          sx={{
            [theme.breakpoints.up('md')]: {
              gap: '1.5rem',
              width: '80%'
            },
            [theme.breakpoints.down('md')]: {
              gap: '1rem',
              width: '100%'
            },
            [theme.breakpoints.down('sm')]: {
              gap: '.75rem',
              width: '100%'
            }
          }}
        >
          <ProfileDataCardView account={user} />
          {
            user.oAuthProvider === ProviderType.PASSWORD &&
              <ChangePwdCardView />
          }
          <PackageCardView 
            package={user.package}
            isDemo={user.demo}
          />
          <SettlementCardView />
        </Grid>
      </Grid>
    </Grid>
  )
}