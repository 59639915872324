import moment from 'moment'
import { Common, Reservation, Trainee } from '../api/data/types'
import { TraineeOption } from '../types/filters'
import { Questionnaire, ScheduleDay, SearchData, TraineeDetails, TraineeList, TraineeReservation } from '../types/trainees'
import { mapContactDto, mapFullName } from './common'
import { mapListEventDto } from './reservation'
import { ListTrainee, Trainee as TraineeType } from '../types/reservations'
import { InviteTraineeFormData } from '../types/form/trainees'

export function mapSearchDtoList(data: Trainee.SearchDto[]): SearchData[] {
  return data.map(mapSearchDto)
}

export function mapSearchDto(trainee: Trainee.SearchDto): SearchData {
  return {
    uuid: trainee.uuid,
    firstName: trainee.firstName,
    lastName: trainee.lastName,
    fullName: mapFullName(trainee.firstName, trainee.lastName),
    note: trainee.note
  }
}

export function mapSearchDataList(data: SearchData[]): TraineeOption[] {
  return data.map(mapSearchData)
}

export function mapSearchData(data: SearchData): TraineeOption {
  return {
    value: data.uuid,
    label: mapFullName(data.firstName, data.lastName)
  }
}

export function mapListDto(data: Trainee.ListDto): TraineeList {
  return {
    totalCount: data.totalCount,
    trainees: data.trainees.map(mapListEntryDto)
  }
}

export function mapListEntryDto(trainee: Trainee.ListEntryDto): ListTrainee {
  return {
    uuid: trainee.uuid,
    firstName: trainee.firstName,
    lastName: trainee.lastName,
    fullName: mapFullName(trainee.firstName, trainee.lastName),
    note: trainee.note
  }
}

export function mapTraineeReservationList(data: TraineeReservation[]): ScheduleDay[] {
  const schedule: ScheduleDay[] = []
  let idx
  for (const reservation of data) {
    idx = schedule.findIndex(s => s.date === reservation.event.formattedDate)
    if (idx === -1) {
      schedule.push({
        date: reservation.event.formattedDate,
        reservations: [reservation]
      })
    } else {
      schedule[idx].reservations.push(reservation)
    }
  }
  return schedule.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
}

export function mapDetailsDto(trainee: Trainee.DetailsDto): TraineeDetails {
  return {
    uuid: trainee.uuid,
    firstName: trainee.firstName,
    lastName: trainee.lastName,
    fullName: mapFullName(trainee.firstName, trainee.lastName),
    reservations: trainee.reservations.map(mapTraineeReservation),
    contact: mapContactDto(trainee.contact),
    note: trainee.note,
    questionnaire: trainee.questionnaire ? mapQuestionnaireDto(trainee.questionnaire) : null,
    stripeId: trainee.stripeId,
    package: trainee.package
  }
}

export function mapQuestionnaireDto(questionnaire: Trainee.QuestionnaireDto): Questionnaire {
  return {
    uuid: questionnaire.uuid,
    suggestedTrainingMethod: questionnaire.suggestedTrainingMethod,
    trainingGoals: questionnaire.trainingGoals,
    answers: [
      questionnaire.answers['1'].reverse(), // Done because order in array and display order is reversed
      questionnaire.answers['2'],
      questionnaire.answers['3'],
      questionnaire.answers['4'],
      questionnaire.answers['5'],
      questionnaire.answers['6'],
    ]
  }
}

export function mapTraineeReservation (reservation: Reservation.TraineeReservationDto): TraineeReservation {
  return {
    uuid: reservation.uuid,
    trainingRoom: reservation.trainingRoom,
    event: mapListEventDto(reservation.event),
    place: reservation.place
  }
}

export function mapTraineeDto(trainee: Reservation.TraineeDto): TraineeType {
  return {
    uuid: trainee.uuid,
    firstName: trainee.firstName,
    lastName: trainee.lastName,
    fullName: mapFullName(trainee.firstName, trainee.lastName),
    contactEmail: trainee.contactEmail,
    phone: trainee.phone,
    note: trainee.note
  }
}

export function mapListTraineeDto(trainee: Reservation.ListTraineeDto): ListTrainee {
  return {
    uuid: trainee.uuid,
    firstName: trainee.firstName,
    lastName: trainee.lastName,
    fullName: mapFullName(trainee.firstName, trainee.lastName),
    note: trainee.note
  }
}

export function mapInviteTraineeFormData(data: InviteTraineeFormData): Common.EmailDto {
  return {
    email: data.email.trim()
  }
}