import { Add } from '@mui/icons-material'
import { Button, Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditIcon from '../../icons/EditIcon'
import EditTraineeNoteDialog from '../../dialogs/EditTraineeNoteDialog'

type Props = {
  uuid: string
  note: string | null
}

export default function NoteSection(props: Props) {
  const texts = useTranslation('traineeDetails').t
  const theme = useTheme()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  
  return (
    <>
      <EditTraineeNoteDialog
        open={isDialogOpen}
        uuid={props.uuid}
        note={props.note}
        onClose={() => { setIsDialogOpen(false) }}
      />
      <Grid
        sx={{
          [theme.breakpoints.up('md')]: {
            marginTop: '.6rem'
          },
          [theme.breakpoints.down('md')]: {
            marginTop: 0
          }
        }}
      >
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h3'>
            {texts('note_title')}
          </Typography>
        </Grid>
        <Card
          sx={{
            [theme.breakpoints.up('md')]: {
              marginTop: '1rem',
            },
            [theme.breakpoints.down('md')]: {
              marginTop: '.75rem',
            },
            [theme.breakpoints.down('sm')]: {
              marginTop: '.5rem',
            }
          }}
        >
          <CardContent>
            {
              props.note
              ? <Grid marginBottom='1rem'>
                  <Typography variant='body1'>
                    {props.note}
                  </Typography>
                </Grid>
              : <Grid
                  container
                  justifyContent='center'
                  marginY='1rem'
                >
                  <Typography variant='body1' color='#BBBBBB'>
                    {texts('no_note')}
                  </Typography>
                </Grid>
            }
            <Grid
              container
              justifyContent='flex-end'
            >
              <Button
                variant='text'
                onClick={() => {
                  setIsDialogOpen(true)
                }}
              >
                <Grid
                  container
                  alignItems='center'
                >
                  {
                    props.note
                    ? <>
                        <EditIcon sx={{ marginTop: '.125rem' }} />
                        {texts('edit_note_button_label')}
                      </>
                    : <>
                        <Add sx={{ marginTop: '-.125rem' }} />
                        {texts('add_note_button_label')}
                      </>
                  }
                </Grid>
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}
