import { Button, Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReservationStatus } from '../../../types/reservations'
import CancelReservationDialog from '../../dialogs/CancelReservationDialog'
import { ReservationDetails } from '../../../types/reservations'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  reservation: ReservationDetails
}

export default function ControlSection(props: Props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const texts = useTranslation('reservationDetails').t

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)

  if (props.reservation.status === ReservationStatus.ACTIVE) {
    return (
      <>
        <CancelReservationDialog
          open={isCancelDialogOpen}
          uuid={props.reservation.uuid}
          date={props.reservation.event.startDate.format('DD.MM.YYYY')}
          time={props.reservation.event.formattedStartTime}
          onClose={() => { setIsCancelDialogOpen(false) }}
        />
        <Grid
          container
          gap='.5rem'
          flexWrap='nowrap'
          sx={{
            [theme.breakpoints.up('md')]: {
              width: 'max-content'
            },
            [theme.breakpoints.down('md')]: {
              width: 'max-content'
            },
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              justifyContent: 'flex-end'
            }
          }}
        >
          <Button
            variant='contained'
            onClick={() => { setIsCancelDialogOpen(true) }}
            sx={{
              backgroundColor: theme.palette.error.main,
              '&:hover': {
                backgroundColor: theme.palette.error.main
              },
              [theme.breakpoints.up('md')]: {
                width: '15.625rem'
              },
              [theme.breakpoints.down('md')]: {
                width: '11.25rem'
              },
              [theme.breakpoints.down('sm')]: {
                width: '50%'
              }
            }}
          >
            {texts('cancel_reservation_button_label')}
          </Button>
        </Grid>
      </>
      
    )
  }

  return (
    <Grid
      container
      gap='.5rem'
      sx={{
        [theme.breakpoints.up('md')]: {
          width: 'max-content'
        },
        [theme.breakpoints.down('md')]: {
          width: 'max-content'
        },
        [theme.breakpoints.down('sm')]: {
          width: 'auto'
        }
      }}
    >
      <Button
        variant='contained'
        onClick={() => {
          navigate(`/locations/${props.reservation.place.uuid}/trainingroom/${props.reservation.trainingRoom.uuid}/calendar`)
        }}
        sx={{
          [theme.breakpoints.up('md')]: {
            width: '15.625rem'
          },
          [theme.breakpoints.down('md')]: {
            width: '11.25rem'
          },
          [theme.breakpoints.down('sm')]: {
            width: '100%'
          }
        }}
      >
        {texts('reserve_again_button_label')}
      </Button>
    </Grid>
  )
}