import { AccessTime, LocationOnOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, Grid, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { useCreateReservation } from '../../../hooks/data/reservations/useCreateReservation'
import useSearchTrainees from '../../../hooks/data/trainees/useSearchTrainees'
import { mapSearchDataList as mapTraineeSearchDataList } from '../../../mapping/trainees'
import { Event } from '../../../types/events'
import { TraineeOption, getDefaultTraineeOption } from '../../../types/filters'
import { defaultTraineeOptionValue } from '../../../utils/const'
import Autocomplete from '../../customMui/Autocomplete'
import BaseDialog from '../../customMui/BaseDialog'
import InfoBlock from './InfoBlock'
import { useQueryClient } from '@tanstack/react-query'
import { queryNames } from '../../../hooks/queries'
import { isDataError } from '../../../services/errors/utils'
import { DataSubcodes } from '../../../services/errors/consts'
import { useNavigate } from 'react-router-dom'

type Props = {
  open: boolean
  event: Event
  onClose: () => void
  onNoActivePackage: (traineeName: string) => void
}

export default function ConfirmReservationDialog(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('confirmReservationDialog').t
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [selectedTrainee, setSelectedTrainee] = useState<TraineeOption>(getDefaultTraineeOption(texts('default_select_option_label')))
  const [traineeSearch, setTraineeSearch] = useState<string>('')

  const traineeList = useSearchTrainees(traineeSearch)
  const mutation = useCreateReservation()

  const debounceTraineeSearch = useDebouncedCallback((value) => {
    setTraineeSearch(value)
    queryClient.resetQueries([queryNames.searchTrainees, value])
  }, 300)

  const handleSubmit = () => {
    mutation.mutate({
      eventUuid: props.event.uuid,
      ...(selectedTrainee.value !== defaultTraineeOptionValue ? { traineeUuid: selectedTrainee.value } : {})
    }, {
      onSettled: props.onClose,
      onError: (error) => {
        if (isDataError(error, [DataSubcodes.NO_ACTIVE_TRAINEE_PACKAGE, DataSubcodes.NO_TRAINEE_ENTRIES_LEFT])) {
          props.onNoActivePackage(selectedTrainee.label)
        } else if (isDataError(error, [DataSubcodes.PLACE_INACTIVE, DataSubcodes.TRAINING_ROOM_INACTIVE])) {
          navigate(-1)
        }
      }
    })
  }

  useEffect(() => {
    setSelectedTrainee(getDefaultTraineeOption(texts('default_select_option_label')))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  return (
    <BaseDialog
      open={props.open}
      title={texts('title')}
      onCloseButtonClick={props.onClose}
      sx={{
        '.MuiDialog-paper': {
          [theme.breakpoints.up('md')]: {
            minWidth: '37.5rem'
          },
          [theme.breakpoints.down('md')]: {
            minWidth: '32.5rem'
          },
          [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            width: '17.5rem'
          }
        }
      }}
    >
      <Grid
        container
        flexDirection='column'
        gap='1rem'
        sx={{
          [theme.breakpoints.up('sm')]: {
            marginTop: '1.5rem',
            gap: '1.5rem'
          },
          [theme.breakpoints.down('sm')]: {
            marginTop: '1.5rem',
            gap: '1rem'
          }
        }}
      >
        <Grid>
          <Typography
            variant='h4'
            color='#BBBBBB'
          >
            {texts('date_section_title')}
          </Typography>
          <InfoBlock icon={AccessTime}>
            <Grid 
              gridArea='1 / 2 / 2 / 3'
              container
              alignItems='center'
            >
              <Typography 
                variant='body1'
                fontWeight={700}
              >
                {texts('date_label', {
                  date: moment(props.event.start).format('DD.MM.YYYY'),
                  time: moment(props.event.start).format('HH:mm')
                })}
              </Typography>
            </Grid>
            <Grid gridArea='2 / 2 / 3 / 3'>
              <Typography variant='body1'>
                {texts('duration_label', { duration: props.event.duration })}
              </Typography>
            </Grid>
          </InfoBlock>
        </Grid>
        <Grid>
          <Typography
            variant='h4'
            color='#BBBBBB'
          >
            {texts('location_section_title')}
          </Typography>
          <InfoBlock icon={LocationOnOutlined}>
            <Grid 
              gridArea='1 / 2 / 2 / 3'
              container
              alignItems='center'
            >
              <Typography 
                variant='body1'
                fontWeight={700}
              >
                {texts(
                  'location_label',
                  {
                    location: props.event.place.name,
                    room: props.event.trainingRoom.name
                  }
                )}
              </Typography>
            </Grid>
            <Grid gridArea='2 / 2 / 3 / 3'>
              <Typography variant='body1'>
                {texts(
                  'address_label',
                  {
                    streetAddress: props.event.place.address.streetAddress,
                    postalCode: props.event.place.address.postalCode,
                    town: props.event.place.address.town
                  }
                )}
              </Typography>
            </Grid>
          </InfoBlock>
        </Grid>
        <Grid>
          <Typography
            variant='h4'
            color='#BBBBBB'
          >
            {texts('for_section_title')}
          </Typography>
          <InfoBlock icon={AccessTime}>
            <Grid 
              gridArea='1 / 2 / 2 / 3'
              container
              alignItems='center'
            >
              <Typography 
                variant='body1'
                fontWeight={700}
              >
                {texts('for_label')}
              </Typography>
            </Grid>
            <Grid 
              gridArea='2 / 2 / 3 / 3'
              marginTop='1rem'
              maxWidth='20rem'
            >
              <Autocomplete<TraineeOption>
                label={texts('for_select_label')}
                defaultValue={getDefaultTraineeOption(texts('default_select_option_label'))}
                loading={traineeList.isFetching}
                options={[
                  ...(
                    texts('default_select_option_label').includes(traineeSearch) 
                    ? [getDefaultTraineeOption(texts('default_select_option_label'))]
                    : []
                  ),
                  ...mapTraineeSearchDataList(traineeList.data ?? [])
                ]}
                onInputChange={(event, value) => {
                  debounceTraineeSearch(event?.type === 'change' ? value : '')
                }}
                onChange={(value) => { setSelectedTrainee(value) }}
              />
            </Grid>
          </InfoBlock>
        </Grid>
      </Grid>
      <DialogActions sx={{ marginTop: '1rem' }}>
        <Button
          variant='outlined'
          size='medium'
          onClick={props.onClose}
          sx={{
            width: '10rem',
            height: '2rem'
          }}
        >
          {texts('cancel')}
        </Button>
        <LoadingButton
          variant='contained'
          size='medium'
          disabled={!selectedTrainee}
          loading={mutation.isLoading}
          onClick={handleSubmit}
          sx={{
            width: '10rem',
            height: '2rem'
          }}
        >
          {texts('confirm')}
        </LoadingButton>
      </DialogActions>
    </BaseDialog>
  )
}
