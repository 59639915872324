import { Namespace, TFuncKey } from 'i18next'
import { QueryParamConfig } from 'use-query-params'

export type Success<T = any> = {
  success: true
  data?: T
}

export type HttpError = {
  httpStatus: number
  code: number
  subcode: number
  message: string
  data?: any
}

export enum ProviderType {
  PASSWORD,
  GOOGLE,
  APPLE,
  FACEBOOK
}

export type BaseListSearchParams = {
  page: QueryParamConfig<number | null | undefined, number>
}

export type AutocompleteBaseOption = {
  value: string
  label: string
}

export type RadioButtonOption = {
  label: string
  value: string
}

export type SelectOption = {
  value: number | string
  label?: string
  i18?: {
    text: TFuncKey<Namespace>
    params?: { [key in string]: string | number }
  }
}

export type OauthData = {
  token: string
  nonce?: string
}

export type Address = {
  streetAddress: string
  postalCode: string
  town: string
}

export type Contact = {
  phone: string
  email: string
}

export type Place = {
  uuid: string
  name: string
  tz: string
  address: Address
  coordinates: number[]
  contact: Contact
}

export type Coordinates = {
  lat: number
  lng: number
}

export type Photo = {
  original: string
  thumbnail: string | null
}

export type Equipment = {
  uuid: string
  name: string
  photo: Photo
}