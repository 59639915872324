import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import RegistrationMethods from '../../components/pages/auth/registration/RegistrationMethods'
import ActivateAccount from '../../components/pages/auth/registration/ActivateAccount'
import IntroduceYourself from '../../components/pages/auth/registration/IntroduceYourself'
import { OauthData, ProviderType } from '../../types/common'

export enum Step {
  REGISTRATION_METHODS,
  INTRODUCE_YOURSELF,
  ACTIVATE_ACCOUNT
}

type LocationState = {
  step?: Step
  oauthData?: OauthData
  email?: string
  accountType?: ProviderType
}

export default function Registration() {
  const locationState: LocationState | null = useLocation().state

  const [step, setStep] = useState<Step>(locationState?.step ?? Step.REGISTRATION_METHODS)
  const [accountType, setAccountType] = useState<ProviderType>(locationState?.accountType ?? ProviderType.PASSWORD)
  const [email, setEmail] = useState<string | undefined>(locationState?.email ?? undefined)
  const [oauthData, setOauthData] = useState<OauthData | undefined>(locationState?.oauthData ?? undefined)
  
  //TODO add GA screen name

  switch (step) {
    case Step.REGISTRATION_METHODS:
      return <RegistrationMethods 
        onSuccess={(options) => {
          setAccountType(options.accountType)
          setEmail(options.email)
          setStep(options.nextStep)
          setOauthData(options.oauthData)
        }}
      />
    case Step.INTRODUCE_YOURSELF:
      return <IntroduceYourself 
        accountType={accountType}
        email={email}
        oauthData={oauthData}
        onSuccess={() => {
          if (accountType === ProviderType.PASSWORD) {
            setStep(Step.ACTIVATE_ACCOUNT)
          }
        }}
      />
    case Step.ACTIVATE_ACCOUNT:
      return <ActivateAccount email={email!} />
  }
}