import { useTranslation } from 'react-i18next'
import Dialog from '../customMui/Dialog'

type Props = {
  open: boolean
  onClose: () => void
}

export default function NoEntriesLeftDialog(props: Props) {
  const texts = useTranslation('noEntriesLeftDialog').t

  return (
    <Dialog
      open={props.open}
      title={texts('title')}
      description={texts('description')}
      confirmButtonLabel={texts('confirm')}
      onCloseButtonClick={props.onClose}
      onConfirmButtonClick={props.onClose}
    />
  )
}
