import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { CardActions, IconButton, Menu, MenuItem, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Trainee } from '../../../types/trainees'
import { useNavigate } from 'react-router-dom'

type Props = {
  trainee: Trainee
  onUnlink: () => void
}

export default function TraineeCardViewActions(props: Props) {
  const theme = useTheme()
  const texts = useTranslation('traineeList').t
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <CardActions
      sx={{
        position: 'absolute',
        right: 16,
        top: 16
      }}
    >
      <IconButton
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        sx={{
          color: '#000',
          padding: 0
        }}
      >
        <MoreHorizIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem 
          onClick={() => {
            navigate('/locations')
          }}
        >
          {texts('reserve_date_tile_menu_label')}
        </MenuItem>
        <MenuItem 
          onClick={() => {
            handleClose()
            props.onUnlink()
          }}
          sx={{
            color: theme.palette.error.main
          }}
        >
          {texts('writ_off_trainee_tile_menu_label')}
        </MenuItem>
      </Menu>
    </CardActions>
  )
}